import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactExport from 'react-data-export';

const initialState = {
    search_input: "",
    category: "",
    filename: ""

}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Item() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    },
    );

    const [userData, setUserData] = useState([]);


    const lodeUserData = async () => {
        await axios.post(API_URL + "/profile", {},
            {
                withCredentials: true,
            })
            .then((resp) => setUserData({ ...resp.data[0] }))

    }

    useEffect(() => {
        lodeData();
        lodeUserData();
        lodeCategoryData();
    }, []);

    function setNohowAdmin() {
        if (userData.account_type === "user") {
            return true;
        } else {
            return false;
        }
    }
    const noshowAdmin = setNohowAdmin();

    function setNohowSuperAdmin() {
        if (userData.account_type === "super_admin") {
            return false;
        } else {
            return true;
        }
    }
    const noshowSuperAdmin = setNohowSuperAdmin();

    const [itemsdata, setData] = useState([]);


    const lodeData = async () => {
        const response = await axios.get(API_URL + "/allItems");
        setData(response.data);

    }

    const [categorydata, setCategoryData] = useState([]);

    const lodeCategoryData = async () => {
        const response = await axios.get(API_URL + "/selectcategorydata");
        setCategoryData(response.data);

    }

    const [state, setState] = useState(initialState);
    const { search_input, category, filename } = state;

    const handleSearch = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchItems/${search_input}`)
        setData(response.data);
    }

    const handleCategory = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/categoryItems/${category}`)
        setData(response.data);
    }

    const handleReorder = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + "/reorderLevel")
        setData(response.data);
    }

    const handleReset = (e) => {

        lodeData()
            .then(() => {
                setState({
                    search_input: "",
                    category: "",
                    filename: ""
                });
            });
    };

    const DataSet = [
        {
            columns: [
                { title: "Item Code", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Item Name", style: { font: { sz: "12", bold: true } }, width: { wpx: 300 } },
                { title: "Unit", style: { font: { sz: "12", bold: true } }, width: { wpx: 50 } },
                { title: "Category", style: { font: { sz: "12", bold: true } }, width: { wpx: 140 } },
                { title: "Reorder Level", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Stock", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },

            ],

            data: itemsdata.map((items) => [
                { value: items.item_code, style: { font: { sz: "12" } } },
                { value: items.item_name, style: { font: { sz: "12" } } },
                { value: items.unit, style: { font: { sz: "12" } } },
                { value: items.category, style: { font: { sz: "12" } } },
                { value: items.reorder_level, style: { font: { sz: "12" } } },
                { value: items.stock, style: { font: { sz: "12" } } },
            ])
        }
    ]

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='contentbox p-1'>
                <div className='row'>
                    <div className='col-3'>
                        <NavBar />
                    </div>


                    <div className=' col-9'>
                        <div className='row mt-2'>
                            <div className='col-9'>
                                <h2>Items</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div>

                                <div className='fleft mx-3 mt-2'>
                                    <div className='input-group'>
                                        <div className="form-outline">
                                            <select name='category' className="form-control form-control-sm" value={category} onChange={handleInputChange}>
                                                <option value="">Category...</option>
                                                {
                                                    categorydata.map((getcategory, index) => (
                                                        <option value={getcategory.category_name} key={index}>{getcategory.category_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <button type="button" className="btn btn-sm btn-success" onClick={handleCategory}><icons.FaFilter /></button>
                                    </div>
                                </div>

                                <div className='fleft mt-2 mx-3'>
                                    <div className='input-group'>
                                        <div className="form-outline">
                                            <input type="search" className="form-control form-control-sm" placeholder='Search...' name='search_input' value={search_input} onChange={handleInputChange}></input>

                                        </div>
                                        <button type="button" className="btn btn-sm btn-success" onClick={handleSearch}><icons.FaSearch /></button>
                                    </div>
                                </div>

                                <div className='fleft mt-2 mx-3'>
                                    <button type='button' className='btn btn-sm btn-success ' onClick={handleReorder}>Reorder Level</button>
                                </div>

                                <div className='fleft mt-2 mx-3'>
                                    <button type='button' className='btn btn-sm btn-success ' onClick={handleReset}>Reset</button>
                                </div>
                            </div>
                        </div>

                        <div className='input-group'>
                            <div className='mt-2 mx-3'>
                                <div className="form-outline fleft ">
                                    <input type='text' className="form-control form-control-sm" placeholder='Enter File Name' name='filename' value={filename} onChange={handleInputChange}></input>
                                </div>
                                <ExcelFile
                                    filename={filename || "Items"}
                                    element={<button type="button" className="btn btn-sm btn-success">Export Data</button>}>
                                    <ExcelSheet dataSet={DataSet} name="Items List" />
                                </ExcelFile>
                            </div>
                            {noshowAdmin ?
                                <div className='fleft mx-3 mt-2'><a href='/additem' className='btn btn-sm btn-outline-success' role='button'><icons.FaPlusCircle /> Add Item</a></div>
                                : null}

                        </div>



                        <div className='mt-3'>

                            <div className='table-responsive me-3'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr className='text-center'>
                                            <th scope="col">#</th>
                                            <th scope="col">Item Code</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Unit</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Use Account</th>
                                            <th scope="col">Reorder Level</th>
                                            <th scope="col">Stock</th>
                                            {noshowSuperAdmin ? <th scope="col">Action</th> : null}

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {itemsdata.map((items, index) => (
                                            <tr key={index}>

                                                <th scope='row'>{index + 1}</th>

                                                <td>{items.item_code}</td>
                                                <td>{items.item_name}</td>
                                                <td className='text-center'>{items.unit}</td>
                                                <td>{items.category}</td>
                                                <td className='text-center'>{items.is_account === 1 ? 'Yes' : 'No'}</td>
                                                <td className='text-end'>{items.reorder_level}</td>
                                                <td className='text-end'>{items.stock}</td>

                                                {noshowSuperAdmin ?
                                                    <td className='d-flex'>

                                                        <Link to={`/edititem/${items.id}`}>
                                                            <icons.FaEdit />
                                                        </Link>

                                                    </td>
                                                    : null}

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
