import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


const initialState = {
    date_time: "",
    item_code: "",
    item_name: "",
    quntity: "",
    unit_price: "",
    amount: "",
    use_for: "-",
    discription: "-",
    user_id: ""
}
export default function Issue() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });


    const [userData, setUserData] = useState([]);
    useEffect(() => {

        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }

        lodeUserData();
    }, []);


    const [state, setState] = useState(initialState);

    const [isLoading, setIsLoading] = useState(false);


    const { date_time, item_code, quntity, unit_price, discription, use_for } = state;

    const [itemNameData, setItemNameData] = useState([]);

    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const response = await axios.get(API_URL + `/getItemName/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemNameData(response.data[0]);

        }

    }

    let user_id = userData.user_id;
    let amount = quntity * unit_price;
    let item_name = itemNameData.item_name;
    let is_account = itemNameData.is_account;
    let category = itemNameData.category;
    let user_fname = userData.name;


    const [fualusedata, setFualUseData] = useState([]);
    const [fualusedatadescription, setFualUseDataDescription] = useState([]);

    useEffect(() => {
        const lodeFualUseData = async () => {
            const response = await axios.get(API_URL + `/usefordata${category}`);
            setFualUseData(response.data);
        }

        lodeFualUseData();

    }, [category]);

    useEffect(() => {
        const lodeFualUseDataDescription = async () => {
            const response = await axios.get(API_URL + `/usedescription${use_for}`);
            setFualUseDataDescription(response.data);
        }

        lodeFualUseDataDescription();

    }, [use_for]);



    const handleSubmit = (e) => {
        e.preventDefault();
        if (is_account === 1) {
            if (!date_time || !item_code || !item_name || !quntity || !unit_price || !amount || !discription || !use_for) {
                toast.error("Please fill in the required fields!");
            } else {
                setIsLoading(true);
                axios.post(API_URL + "/issueTransaction", {
                    date_time,
                    item_code,
                    item_name,
                    quntity,
                    unit_price,
                    amount,
                    use_for,
                    discription,
                    user_id,
                    user_fname
                })
                    .then(() => {
                        setState({
                            date_time: "",
                            item_code: "",
                            item_name: "",
                            quntity: "",
                            unit_price: "",
                            amount: "",
                            use_for: "",
                            discription: "",
                            user_id: ""
                        });
                        toast.success("Success!");
                    })
                    .catch((err) => {
                        toast.error(err.response.data);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        } else {
            if (!date_time || !item_code || !item_name || !quntity || !discription || !use_for) {
                toast.error("Please fill in the required fields!");
            } else {
                setIsLoading(true);
                axios.post(API_URL + "/issueTransaction", {
                    date_time,
                    item_code,
                    item_name,
                    quntity,
                    unit_price,
                    amount,
                    use_for,
                    discription,
                    user_id,
                    user_fname
                })
                    .then(() => {
                        setState({
                            date_time: "",
                            item_code: "",
                            item_name: "",
                            quntity: 0,
                            unit_price: 0,
                            amount: 0,
                            use_for: "",
                            discription: "",
                            user_id: ""
                        });
                        toast.success("Success!");
                    })
                    .catch((err) => {
                        toast.error(err.response.data);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    }


    const [showFual, setshowFual] = useState(false);

    useEffect(() => {
        if (itemNameData.category === "fuel") {
            setshowFual(true);
        } else {
            setshowFual(false);
        }
    }, [itemNameData]);

    const handleTypeDiscription = (e) => {
        e.preventDefault();
        setshowFual(!showFual);
    };

    const [showFualBtn, setshowFualBtn] = useState(false);

    useEffect(() => {
        if (itemNameData.category === "fuel") {
            setshowFualBtn(true);
        } else {
            setshowFualBtn(false);
        }
    }, [itemNameData]);



    const [showfpp, setShowfpp] = useState(false);

    useEffect(() => {
        if (itemNameData.category === "fuel" || itemNameData.category === "use_polythene_roll" || itemNameData.category === "use_polythene") {
            setShowfpp(true);
        } else {
            setShowfpp(false);
        }

    }, [itemNameData]);




    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Issue</h4>
                        </div>
                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form>
                            <div>
                                <h6 className="mt-2">Date:</h6>
                                <input type="date" className="form-control form-control-sm" name='date_time' value={date_time} onKeyDown={handleEnter} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Item Code:</h6>
                                <input type='text' placeholder='Enter Item Code' className="form-control form-control-sm" name='item_code' value={item_code} onKeyDown={handleEnter} onChange={handleInputChange}></input>
                            </div>
                            <button className='btn btn-sm btn-secondary mt-2' onKeyDown={handleEnter} onClick={handleCheckName}>Check Name</button>
                            <div>
                                <h6 className="mt-2">Item Name:</h6>
                                <input type='text' readOnly placeholder='Check Item Name' className="form-control form-control-sm" name='item_name' onKeyDown={handleEnter} value={item_name || ''} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Item Quantity:</h6>
                                <input type='text' placeholder='Enter Item Quntity' className="form-control form-control-sm" name='quntity' onKeyDown={handleEnter} value={quntity} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit Price:</h6>
                                <input type='text' placeholder='Enter Unit Price' className="form-control form-control-sm" name='unit_price' onKeyDown={handleEnter} value={unit_price} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Amount:</h6>
                                <input type='text' placeholder='Check Amount' className="form-control form-control-sm" name='amount' onKeyDown={handleEnter} value={amount} onChange={handleInputChange}></input>
                            </div>
                            {showfpp ? <div>
                                <h6 className="mt-2">Use for:</h6>
                                <select name="use_for" className="form-control form-control-sm" onKeyDown={handleEnter} value={use_for} onChange={handleInputChange}>
                                    <option value="">Select use_for...</option>
                                    {
                                        fualusedata.map((getcategory, index) => (
                                            <option value={getcategory.use_for} key={index}>{getcategory.use_for}</option>
                                        ))
                                    }
                                </select>
                            </div> : null}

                            {showFual ? <div >
                                <h6 className="mt-2">Discription:</h6>
                                <select name="discription" className="form-control form-control-sm fleft" onKeyDown={handleEnter} value={discription} onChange={handleInputChange}>
                                    <option value="">Select discription...</option>
                                    {
                                        fualusedatadescription.map((getsubcategory, index) => (
                                            <option value={getsubcategory.description} key={index}>{getsubcategory.description}</option>
                                        ))
                                    }
                                </select>

                            </div> : null}



                            {!showfpp ?
                                <div>
                                    <h6 className="mt-2">Use for:</h6>
                                    <input type='text' placeholder='Enter Use for' className="form-control form-control-sm" name='use_for' onKeyDown={handleEnter} value={use_for} onChange={handleInputChange}></input>
                                </div> : null}

                            {!showFual ?
                                <div>
                                    <h6 className="mt-2">Discription:</h6>
                                    <input type='text' placeholder='Enter Discription' className="form-control form-control-sm" name='discription' onKeyDown={handleEnter} value={discription} onChange={handleInputChange}></input>
                                </div> : null}

                            {showFualBtn ? <div >
                                <button className="btnToType" onKeyDown={handleEnter} onClick={handleTypeDiscription}>{showFual === true ? "To type description" : "To select description"}</button>
                            </div> : null}

                            <div>
                                <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleSubmit} disabled={isLoading}>
                                    {isLoading ? 'Saving...' : 'Save'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}