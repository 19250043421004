import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
    item_code: "",
    item_name: "",
    unit: "",
    category: "",
    is_account: "",
    reorder_level: "",
    stock: ""
};

export default function Additem() {
    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setuserData] = useState([]);

    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setuserData({ ...resp.data[0] }))

        };
        lodeUserData();
    }, []);

    if (userData.account_type === "admin" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const [categorydata, setCategoryData] = useState([]);

    const lodeCategoryData = async () => {
        const response = await axios.get(API_URL + "/selectcategorydata");
        setCategoryData(response.data);

    }

    const [unitdata, setUnitData] = useState([]);

    const lodeUnitData = async () => {
        const response = await axios.get(API_URL + "/selectunitdata");
        setUnitData(response.data);

    }

    useEffect(() => {
        lodeCategoryData();
        lodeUnitData();
    }, []);

    const [state, setState] = useState(initialState);

    const { item_code, item_name, unit, category, is_account, reorder_level } = state;
    const stock = 0;
    let user_id = userData.user_id;
    let user_fname = userData.name;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name || !unit || !category || !reorder_level) {
            toast.error("Please fill in all data!");
        } else {

            axios.post(API_URL + "/addItem", {
                item_code,
                item_name,
                unit,
                category,
                is_account,
                reorder_level,
                stock,
                user_id,
                user_fname

            })

                .then(() => {
                    setState({
                        item_code: "",
                        item_name: "",
                        unit: "",
                        category: "",
                        is_account: "",
                        reorder_level: ""
                    });

                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }

    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
            <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Add Item</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>
                            <div>
                                <h6 className="mt-2">Item Code:</h6>
                                <input type='text' name='item_code' placeholder='Enter Item Code' className="form-control form-control-sm" onKeyDown={handleEnter} value={item_code} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Item Name:</h6>
                                <input type='text' name='item_name' placeholder='Enter Item Name' className="form-control form-control-sm" onKeyDown={handleEnter} value={item_name} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit:</h6>
                                <select name="unit" className="form-control form-control-sm" onKeyDown={handleEnter} value={unit} onChange={handleInputChange}>
                                    <option value="">Select Unit...</option>
                                    {
                                        unitdata.map((getunit, index) => (
                                            <option value={getunit.unit_name} key={index}>{getunit.unit_name}</option>
                                        ))
                                    }
                                </select>
                            </div>


                            <div>
                                <h6 className="mt-2">Category:</h6>
                                <select name='category' className="form-control form-control-sm" onKeyDown={handleEnter} value={category} onChange={handleInputChange}>
                                    <option value="">Select Category...</option>
                                    {
                                        categorydata.map((getcategory, index) => (
                                            <option value={getcategory.category_name} key={index}>{getcategory.category_name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div>
                                <h6 className="mt-2">Use Accounts:</h6>
                                <select name='is_account' className="form-control form-control-sm" onKeyDown={handleEnter} value={is_account} onChange={handleInputChange}>
                                    <option value="">Select Use for accounts...</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>

                            <div>
                                <h6 className="mt-2">Reorder Level:</h6>
                                <input type='text' name='reorder_level' placeholder='Enter Reorder Level' className="form-control form-control-sm" onKeyDown={handleEnter} value={reorder_level} onChange={handleInputChange}></input>
                            </div>


                            <button type='submit' className='btn btn-sm btn-success mt-2' >Save</button>

                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
