import React, { useEffect, useState } from "react";
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const initialState={
    name:"",
    position:"",
    email:"",
    contact_no:"",
    account_type:"",
    username:"",
    password:""
};

export default function Newuser() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }

      const[state, setState]=useState(initialState);

    const{name, position, email,contact_no, account_type, username, password}=state;
    const userid = userData.user_id;
    const user_fname = userData.name;

    const handleSubmit=(e)=>{
        e.preventDefault();
        if (!name || !position || !email ||!contact_no || !account_type || !username || !password ) {
            toast.error("Please fill in all data!");
        }else{
            axios.post(API_URL + "/register",{
                name,
                position,
                email,
                contact_no,
                account_type,
                username,
                password,
                userid,
                user_fname

            })
            .then(()=>{
                setState({
                name:"",
                position:"",
                email:"",
                contact_no:"",
                account_type:"",
                username:"",
                password:""
            });

                toast.success("Success!");
            })

            .catch((err)=>toast.error(err.response.data));
           
            
        }

    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
          const form = event.target.form;
          const index = [...form].indexOf(event.target);
          form.elements[index + 1].focus();
          event.preventDefault();
        }}

    
    const handleInputChange=(e)=>{
        const{name,value} = e.target;
        setState({...state,[name]:value})
      };


      return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                  <div className='row'>
                      <div className='col-12'>
                          <div className='fleft'>  
                              <a href="/useraccount">
                                  <icons.FaArrowCircleLeft size='2.5em' color='#274F6A'/>
                              </a>
                          </div>
                          
                          <div className='fleft ms-2'>
                              <h2>Create New User</h2>
                          </div>
                      </div>
                  </div>
  
                  <div className='row'>
                      <div className='col-11 col-md-6 col-sm-9 mt-2'>
                      
                          <form onSubmit={handleSubmit}>
                            <div>
                                 <h5 className="mt-2">Name:</h5>
                                <input type='text' name='name' placeholder='Enter Name' className="form-control"  onKeyDown={handleEnter} 
                                value={name} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Position:</h5>
                                <input type='text' name='position' placeholder='Enter Position' className="form-control"  onKeyDown={handleEnter} 
                                value={position} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Email:</h5>
                                <input type='email' name='email' placeholder='Enter Email' className="form-control" onKeyDown={handleEnter} 
                                 value={email} onChange={handleInputChange}>
                            </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Contact No:</h5>
                                <input type='text' name='contact_no' placeholder='Enter Contact number' className="form-control" onKeyDown={handleEnter} 
                                value={contact_no} onChange={handleInputChange}>
                            </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Account Type:</h5>
                                <select name='account_type' className="form-control"  onKeyDown={handleEnter} 
                                value={account_type} onChange={handleInputChange}>
                                    <option value="">Select Type...</option>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="super_admin">Super Admin</option>
                                </select>
                            </div>

                            <div>
                                <h5 className="mt-2">User Name:</h5>
                                <input type='text' name='username' placeholder='Enter User Name' className="form-control" onKeyDown={handleEnter} 
                                value={username} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Password:</h5>
                                <input type='password' name='password' placeholder='Enter Password' className="form-control mt-2" onKeyDown={handleEnter} 
                                value={password} onChange={handleInputChange}>
                                </input>
                            </div>

                              <button type='submit' className='btn btn-success mt-2'>Create</button>
                          </form>
                          <ToastContainer />
                      </div>
                  </div>
  
              </div>
          </div>
    )
}
  