import React, { Component } from 'react';
import {BrowserRouter,Route, Routes} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';

import Add from './pages/Add';
import Additem from './pages/Additem';
import Adjustminus from './pages/Adjustminus';
import Adjustpluse from './pages/Adjustpluse';
import Edititem from './pages/Edititem';
import Edituser from './pages/Edituser';
import Dashboard from './pages/Dashboard';
import Issue from './pages/Issue';
import Item from './pages/Item';
import Login from './pages/Login';
import Newuser from './pages/Newuser';
import Profile from './pages/Profile';
import Transaction from './pages/Transaction';
import Useracc from './pages/Useracc';
import Purchases from './pages/Purchases';
import Editpurchases from './pages/Editpurchases';
import Quotationall from './pages/Quotationall';
import Addquotation from './pages/Addquotation';
import Editquotation from './pages/Editquotation';
import Viewquotations from './pages/Viewquotations';
import Purchasingmanagement from './pages/Purchasingmanagement';
import Suppliers from './pages/Suppliers';
import Addsuppliers from './pages/Addsuppliers';
import Editsuppliers from './pages/Editsuppliers';
import Unitprice from './pages/Unitprice';
import EditIssueTransaction from './pages/EditIssueTransaction';
import EditAddTransaction from './pages/EditAddTransaction';
import Issuetransactions from './pages/Issuetransactions';
import Addtransactions from './pages/Addtransactions';
import AdjustPtransactions from './pages/AdjustPtransactions';
import EditAdjustPTransaction from './pages/EditAdjustPTransaction';
import AdjustMtransactions from './pages/AdjustMtransactions';
import EditAdjustMTransaction from './pages/EditAdjustMTransaction';
import Viewsuppliers from './pages/Viewsuppliers';
import Logreport from './pages/Logreport';
import Report from './pages/Report';
import Printquotations from './pages/Printquotations';
import Addpo from './pages/Addpo';
import Quotationastoapproval from './pages/Quotationastoapproval';
import Quotationastocheck from './pages/Quotationastocheck';
import Quotationastoorder from './pages/Quotationastoorder';
import Quotationastoverify from './pages/Quotationastoverify';
import POall from './pages/POall';
import ViewPO from './pages/ViewPO';
import Viewemailquotations from './pages/Viewemailquotations';
import Quotationastosend from './pages/Quotationastosend';
import Editpo from './pages/Editpo';


export default class App extends Component {

  render() {
    return (
      <div>
        <BrowserRouter>
        <Routes>
                <Route path="/" exact element={<Login/>}></Route>
                <Route path="/dashboard" element={<Dashboard/>}></Route> 
                <Route path="/Profile" element={<Profile/>}></Route>
                <Route path="/purchases" element={<Purchases/>}></Route>
                <Route path="/quotationsall" element={<Quotationall/>}></Route>
                <Route path="/quotationstoapproval" element={<Quotationastoapproval/>}></Route>
                <Route path="/quotationstoverify" element={<Quotationastoverify/>}></Route>
                <Route path="/quotationstocheck" element={<Quotationastocheck/>}></Route>
                <Route path="/quotationstoorder" element={<Quotationastoorder/>}></Route>
                <Route path="/quotationstosend" element={<Quotationastosend/>}></Route>
                <Route path="/item" element={<Item/>}></Route>
                <Route path="/transaction" element={<Transaction/>}></Route>
                <Route path="/issuetransaction" element={<Issuetransactions/>}></Route>
                <Route path="/addtransaction" element={<Addtransactions/>}></Route>
                <Route path="/adjustptransactions" element={<AdjustPtransactions/>}></Route>
                <Route path="/adjustmtransactions" element={<AdjustMtransactions/>}></Route>
                <Route path="/suppliers" element={<Suppliers/>}></Route>
                <Route path="/purchasingmanagement" element={<Purchasingmanagement/>}></Route>
                <Route path="/useraccount" element={<Useracc/>}></Route>
                <Route path="/unitprice" element={<Unitprice/>}></Route>
                <Route path="/logreport" element={<Logreport/>}></Route>
                <Route path="/report" element={<Report/>}></Route>
                <Route path="/add" element={<Add/>}></Route>
                <Route path="/addquotation" element={<Addquotation/>}></Route>
                <Route path="/issue" element={<Issue/>}></Route>
                <Route path="/adjustminus" element={<Adjustminus/>}></Route>
                <Route path="/adjustpluse" element={<Adjustpluse/>}></Route>
                <Route path="/addsuppliers" element={<Addsuppliers/>}></Route>
                <Route path="/additem" element={<Additem/>}></Route>
                <Route path="/newuser" element={<Newuser/>}></Route>
                <Route path="/edituser/:id" element={<Edituser/>}></Route>
                <Route path="/edititem/:id" element={<Edititem/>}></Route>
                <Route path="/editpurchases/:purchase_id" element={<Editpurchases/>}></Route>
                <Route path="/editsuppliers/:suppliers_id" element={<Editsuppliers/>}></Route>
                <Route path="/editissuetransaction/:transactions_number" element={<EditIssueTransaction/>}></Route>
                <Route path="/editadjustptransaction/:transactions_number" element={<EditAdjustPTransaction/>}></Route>
                <Route path="/editadjustmtransaction/:transactions_number" element={<EditAdjustMTransaction/>}></Route>
                <Route path="/editaddtransaction/:transactions_number" element={<EditAddTransaction/>}></Route>
                <Route path="/editquotation/:quotation_ref_no" element={<Editquotation/>}></Route>
                <Route path="/viewquotations/:quotation_ref_no" element={<Viewquotations/>}></Route>
                <Route path="/viewemailquotations" element={<Viewemailquotations/>}></Route>
                <Route path="/viewsuppliers/:suppliers_id" element={<Viewsuppliers/>}></Route>
                <Route path="/printquotations/:quotation_ref_no" element={<Printquotations/>}></Route>
                <Route path="/editpo/:po_ref_no" element={<Editpo/>}></Route>
                <Route path="/addpo" element={<Addpo/>}></Route>
                <Route path="/poall" element={<POall/>}></Route>
                <Route path="/viewpo/:po_ref_no" element={<ViewPO/>}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}
