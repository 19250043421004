import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

const initialState = {

    qsupplier01: "", qsupplier02: "", qsupplier03: "", qsupplier04: "", remarks: "", for_verify: "",
    item_code: "", item_name: "", qbrand: "", quntity: null, unit: "", qunit_price01: null, qunit_price02: null,
    qunit_price03: null, qunit_price04: null, amount01: null, amount02: null, amount03: null, amount04: null,
    purchase_id: "", lpdate: undefined, lpsupplier: "", lpbrand: "", lpquntity: null, lpunit_price: null,
    use_for: "", reqest_person: "", extra_note: ""

};

export default function Addquotation() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "admin" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const [state, setState] = useState(initialState);

    const {
        qsupplier01, qsupplier02, qsupplier03, qsupplier04, for_verify,
        item_code, quntity, qbrand,
        qunit_price01, qunit_price02, qunit_price03, qunit_price04,
        purchase_id, lpdate, lpsupplier, lpbrand, lpquntity, lpunit_price,
        remarks, use_for, reqest_person, extra_note } = state;

    let prepared_by = userData.name;
    let prepared_position = userData.position;


    const [itemNameData, setItemNameData] = useState([]);
    const [itemUnitData, setItemUnitData] = useState([]);

    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const responsename = await axios.get(API_URL + `/getItemName/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemNameData(responsename.data[0]);

            const responseunit = await axios.get(API_URL + `/getItemunit/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemUnitData(responseunit.data[0]);

        }
    }

    const [forVarifyData, setForVarifyData] = useState([]);

    const lodeForVarifyData = async () => {
        const response = await axios.get(API_URL + "/selectforvarifydata");
        setForVarifyData(response.data);

    }

    useEffect(() => {
        lodeForVarifyData();
    }, []);

    const [QMainData, setQMainData] = useState([]);

    const lodeQMainData = async () => {
        const response = await axios.get(API_URL + "/getQuotationSuppliers");
        setQMainData(response.data[0]);

    }

    let quotation_ref_no = QMainData.quotation_ref_no;

    const [QDetailsData, setQDetailsData] = useState([]);
    console.log(QDetailsData)

    const lodeQDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationDetails/${quotation_ref_no}`);
        setQDetailsData(response.data);

    }


    // const [lastPurchaseData, setLastPurchaseData] = useState([]);
    // console.log(lastPurchaseData)

    // const handleLastPurchase = async (e) => {
    //     e.preventDefault();
    //     if (!purchase_id) {
    //         toast.error("Please enter the item code!");
    //     } else {
    //         const responsename = await axios.get(API_URL + `/getLastPurchase/${purchase_id}`)
    //             .catch((err) => toast.error(err.response.data));
    //         setLastPurchaseData(responsename.data[0]);

    //     }
    // }

    const [isLoading, setIsLoading] = useState(false);

    let item_name = itemNameData.item_name;
    let unit = itemUnitData.unit;
    let response_type = "pending"
    let ordering_status = "pending"
    let for_approval = "pending"
    let verify_type = "pending"
    const amount01 = qunit_price01 * quntity
    const amount02 = qunit_price02 * quntity
    const amount03 = qunit_price03 * quntity
    const amount04 = qunit_price04 * quntity

    const handleSuppliersSubmit = (e) => {
        e.preventDefault();
        if (!qsupplier01 || !qsupplier02 || !qsupplier03 || !qsupplier04 || !remarks || !for_verify) {
            toast.error('Please fill in all data! (If there is no data to enter in the respective field, please enter the "-" mark or "0".)');
        } else {
            setIsLoading(true);
            axios.post(API_URL + "/addQuotationSuppliers", {
                qsupplier01, qsupplier02, qsupplier03, qsupplier04, remarks, for_verify, prepared_by, prepared_position, response_type, verify_type, ordering_status, for_approval

            })
                .then(() => {
                    setState({
                        qsupplier01: "", qsupplier02: "", qsupplier03: "", qsupplier04: "", remarks: ""
                    });
                    lodeQMainData();
                    toast.success("Success!");
                    setIsLoading(false);
                })
                .catch((err) => toast.error(err.response.data));
                setIsLoading(false);
        }

    }

    const handleDetailsSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name) {
            toast.error('Please fill in all data! (If there is no data to enter in the respective field, please enter the "-" mark or "0".)');
        } else {
            setIsLoading(true);
            axios.post(API_URL + "/addQuotation", {
                quotation_ref_no, item_code, item_name, qbrand, quntity, unit, qunit_price01, qunit_price02, qunit_price03, qunit_price04,
                amount01, amount02, amount03, amount04, use_for, reqest_person, purchase_id, lpdate, lpsupplier, lpbrand, lpquntity, lpunit_price, extra_note

            })
                .then(() => {
                    setState({
                        item_code: "", item_name: "", qbrand: "", quntity: null, unit: "", qunit_price01: null, qunit_price02: null,
                        qunit_price03: null, qunit_price04: null, amount01: null, amount02: null, amount03: null, amount04: null,
                        purchase_id: "", lpdate: undefined, lpsupplier: "", lpbrand: "", lpquntity: null, lpunit_price: null,
                        use_for: "", reqest_person: "", extra_note: ""
                    });
                    toast.success("Success!");
                    lodeQDetailsData();
                    setIsLoading(false);
                })
                .catch((err) => toast.error(err.response.data));
                setIsLoading(false);
                
        }

    }

    const handleLoadData = (e) => {
        lodeQMainData()
            .then(() => {
                lodeQDetailsData();
            })

    };

    const handleNew = (e) => {
        window.location.reload()
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };



    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Add Quotation</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className="mt-2">
                            {QDetailsData.length === 0 ?
                                <button type='button' className='btn btn-sm btn-success me-2' onClick={handleLoadData}>Load Data</button> : ""}
                            {QMainData.quotation_ref_no !== undefined ?
                                <button type='button' className='btn btn-sm btn-success' onClick={handleNew}>Add new quotation</button> : ""}
                        </div>

                        <div style={{ outline: "solid" }} className='mt-3' >
                            <div className='table-responsive'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <th scope="row" colSpan="18">Associated Speciality Rubbers (PVT) Ltd. <br /> Quotation for Purchase Order</th>
                                        </tr>
                                        <tr >
                                            <th scope="row" colSpan="18">Quotation Reference No: {quotation_ref_no} <br /> Date: {moment(QMainData.date).format("YYYY/MM/DD")}</th>
                                        </tr>
                                        <tr style={{ textAlign: "center" }}>
                                            <th rowSpan="2" scope="col">No</th>
                                            <th rowSpan="2" scope="col">Item Code</th>
                                            <th rowSpan="2" scope="col">Item Name</th>
                                            <th rowSpan="2" scope="col">Brand</th>
                                            <th rowSpan="2" scope="col">Stock</th>
                                            <th colSpan="4" scope="col">Unit Price</th>
                                            <th rowSpan="2" scope="col">Quntity</th>
                                            <th rowSpan="2" scope="col">Unit</th>
                                            <th colSpan="4" scope="col">Amount</th>
                                            <th rowSpan="2" scope="col">Use For</th>
                                            <th rowSpan="2" scope="col">Request Person</th>
                                            <th rowSpan="2" scope="col">Extra Note</th>
                                        </tr>
                                        <tr style={{ textAlign: "center" }}>
                                            <th scope="col">{QMainData.qsupplier01}</th>
                                            <th scope="col">{QMainData.qsupplier02}</th>
                                            <th scope="col">{QMainData.qsupplier03}</th>
                                            <th scope="col">{QMainData.qsupplier04}</th>
                                            <th scope="col">{QMainData.qsupplier01}</th>
                                            <th scope="col">{QMainData.qsupplier02}</th>
                                            <th scope="col">{QMainData.qsupplier03}</th>
                                            <th scope="col">{QMainData.qsupplier04}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {QDetailsData.map((qDetails, index) => (
                                            <tr key={index}>

                                                <th scope='row'>{index + 1}</th>

                                                <td>{qDetails.item_code}</td>
                                                <td>{qDetails.item_name}</td>
                                                <td>{qDetails.qbrand}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.stock}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.qunit_price01}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.qunit_price02}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.qunit_price03}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.qunit_price04}</td>
                                                <td>{qDetails.quntity}</td>
                                                <td>{qDetails.unit}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.amount01}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.amount02}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.amount03}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.amount04}</td>
                                                <td>{qDetails.use_for}</td>
                                                <td>{qDetails.reqest_person}</td>
                                                <td>{qDetails.extra_note}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <dt className="col-sm-3 mt-3">Last Purchase Details:</dt>
                            <div className='table-responsive me-3'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr style={{ textAlign: "center" }} >
                                            <th scope="col">No</th>
                                            <th scope="col">Item code</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Purchases ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Supplier</th>
                                            <th scope="col">Brand</th>
                                            <th scope="col">Quntity</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {QDetailsData.map((qDetails, index) => (
                                            qDetails.lpquntity !== 0 && qDetails.lpquntity !== null ?
                                            <tr key={index}>
                                                <th scope='row'>{index + 1}</th>
                                                <td>{qDetails.item_code}</td>
                                                <td>{qDetails.item_name}</td>
                                                <td>{qDetails.purchase_id}</td>
                                                <td>{moment(qDetails.lpdate).format("YYYY/MM/DD")}</td>
                                                <td>{qDetails.lpsupplier}</td>
                                                <td>{qDetails.lpbrand}</td>
                                                <td>{qDetails.lpquntity}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.lpunit_price}</td>
                                                <td style={{ textAlign: "right" }}>{qDetails.lpunit_price * qDetails.lpquntity}</td>
                                            </tr>: null
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <dl className="row">
                                    <dt className="col-sm-2">Remarks:</dt>
                                    <dd className="col-sm-9">{QMainData.remarks}</dd>
                                </dl>
                            </div>

                            <div>
                                <dl className="row">
                                    <dt>Prepared by:</dt>
                                    <dd>{QMainData.prepared_position}</dd>
                                    <dd>{QMainData.prepared_by}</dd>
                                </dl>
                            </div>

                        </div>
                    </div>
                </div>

                {QMainData.length === 0 ? <form>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div>
                                <h6 className="mt-2">Suppliers</h6>
                                <input type='text' placeholder='Enter Supplier 01' className="form-control form-control-sm mt-2" name='qsupplier01' onKeyDown={handleEnter} value={qsupplier01.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 02' className="form-control form-control-sm mt-2" name='qsupplier02' onKeyDown={handleEnter} value={qsupplier02.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 03' className="form-control form-control-sm mt-2" name='qsupplier03' onKeyDown={handleEnter} value={qsupplier03.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 04' className="form-control form-control-sm mt-2" name='qsupplier04' onKeyDown={handleEnter} value={qsupplier04.replace(",", "&") || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div>
                                <h6 className="mt-2">Remarks:</h6>
                                <textarea type='text' placeholder='Enter Remarks' className="form-control form-control-sm" name='remarks' onKeyDown={handleEnter} value={remarks || ""} onChange={handleInputChange}></textarea>
                            </div>

                            <div>
                                <h6 className="mt-2">To send for Verify:</h6>
                                <select name="for_verify" className="form-control form-control-sm" value={for_verify} onChange={handleInputChange}>
                                    <option value="">Select who to send to for verify...</option>
                                    {
                                        forVarifyData.map((verifydata, index) => (
                                            <option value={verifydata.verifyto} key={index}>{verifydata.verifytoshow}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleSuppliersSubmit} disabled={isLoading}>
                            {isLoading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form> : ""}

                {QMainData.length !== 0 ? <form >
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div>
                                <h6 className="mt-2">Item Code:</h6>
                                <div className='input-group'>
                                    <input type='text' name='item_code' placeholder='Enter Item Code' className="form-control form-control-sm" onKeyDown={handleEnter} value={item_code || ""} onChange={handleInputChange}></input>
                                    <button className='btn btn-sm btn-secondary' onKeyDown={handleEnter} onClick={handleCheckName}>Check Name</button>
                                </div>
                            </div>

                            <div>
                                <div className="input-group mt-2">
                                    <h6>Item Name:</h6>
                                    <label> &nbsp; &nbsp; &nbsp; {itemNameData.item_name}</label>
                                </div>
                            </div>

                            <div>
                                <h6 className="mt-2">Brand:</h6>
                                <input type='text' placeholder='Enter Brand' className="form-control form-control-sm" name='qbrand' onKeyDown={handleEnter} value={qbrand || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Qunatity:</h6>
                                <div className='input-group'>
                                    <input type='number' placeholder='Enter Item Quantity' className="form-control form-control-sm" name='quntity' onKeyDown={handleEnter} value={quntity || ""} onChange={handleInputChange}></input>
                                    <label > &nbsp; {itemUnitData.unit}</label>
                                </div>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit Price:</h6>
                                <input type='number' placeholder='Enter Supplier 01 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price01' onKeyDown={handleEnter} value={qunit_price01 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 02 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price02' onKeyDown={handleEnter} value={qunit_price02 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 03 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price03' onKeyDown={handleEnter} value={qunit_price03 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 04 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price04' onKeyDown={handleEnter} value={qunit_price04 || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div>
                                <h6 className="mt-2">Use For:</h6>
                                <input type='text' placeholder='Enter Use For' className="form-control form-control-sm" name='use_for' onKeyDown={handleEnter} value={use_for || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Reqest Person:</h6>
                                <input type='text' placeholder='Enter Reqest Person' className="form-control form-control-sm" name='reqest_person' onKeyDown={handleEnter} value={reqest_person || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Last Purchase</h6>
                                <input type="date" className="form-control form-control-sm" name='lpdate' onKeyDown={handleEnter} value={lpdate || ""} onChange={handleInputChange}></input>
                                <input type="text" placeholder='Enter Purchase ID' className="form-control form-control-sm mt-1" name='purchase_id' value={purchase_id || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier' className="form-control form-control-sm mt-1" name='lpsupplier' onKeyDown={handleEnter} value={lpsupplier || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Brand' className="form-control form-control-sm mt-1" name='lpbrand' onKeyDown={handleEnter} value={lpbrand || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Item Quntity' className="form-control form-control-sm mt-1" name='lpquntity' onKeyDown={handleEnter} value={lpquntity || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Unit Price' className="form-control form-control-sm mt-1" name='lpunit_price' onKeyDown={handleEnter} value={lpunit_price || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Extra Note:</h6>
                                <input type='text' placeholder='Enter Extra Note' className="form-control form-control-sm" name='extra_note' onKeyDown={handleEnter} value={extra_note || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleDetailsSubmit} disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}</button>
                        </div>
                    </div>
                </form> : ""}
            </div>
            <ToastContainer />
        </div>
    )
}
