import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import ReactExport from 'react-data-export';

const initialState = {
    purchase_id: "",
    supplier: "",
    startdate: "",
    enddate:"",
    item_code: "",
    filename: ""

}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Purchases() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    },
    );


    const [userData, setUserData] = useState([]);

    const lodeUserData = async () => {
        await axios.post(API_URL + "/profile", {},
            {
                withCredentials: true,
            })
            .then((resp) => setUserData({ ...resp.data[0] }))

    }

    const [data, setData] = useState([]);
    const lodeData = async () => {
        const response = await axios.get(API_URL + "/allPurchases");
        setData(response.data);
    }

    const [state, setState] = useState(initialState);
    const { purchase_id, supplier, startdate, enddate, item_code, filename } = state;
    
    const handleTransNo = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchPurchaseID/${purchase_id}`)
        setData(response.data);
    }

    const handleDate = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchPurchaseDate/${startdate}/${enddate}`)
        setData(response.data);
    }

    const handleItemCode = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchPurchaseItemCode/${item_code}`)
        setData(response.data);
    }

    const handleSupplier = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchSupplier/${supplier}`)
        setData(response.data);
    }

    const handleReset = (e) => {

        lodeData()
            .then(() => {
                setState({
                    purchase_id: "",
                    supplier: "",
                    date: "",
                    item_code: "",
                    filename: ""
                });
            });
    };

    const DataSet = [
        {
            columns: [
                { title: "Purchase ID", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Date", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Item Code", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Item Name", style: { font: { sz: "12", bold: true } }, width: { wpx: 300 } },
                { title: "Quntity", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Unit Price", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Amount", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Supplier ID", style: { font: { sz: "12", bold: true } }, width: { wpx: 120 } },
                { title: "Brand", style: { font: { sz: "12", bold: true } }, width: { wpx: 50 } },
                { title: "Quotation Ref. No.", style: { font: { sz: "12", bold: true } }, width: { wpx: 50 } },


            ],

            data: data.map((purchases) => [

                { value: purchases.purchase_id, style: { font: { sz: "12" } } },
                { value: moment(purchases.date_time).format("YYYY/MM/DD"), style: { font: { sz: "12" } } },
                { value: purchases.item_code, style: { font: { sz: "12" } } },
                { value: purchases.item_name, style: { font: { sz: "12" } } },
                { value: purchases.quntity, style: { font: { sz: "12" } } },
                { value: purchases.unit_price, style: { font: { sz: "12" } } },
                { value: purchases.amount, style: { font: { sz: "12" } } },
                { value: purchases.supplier_id, style: { font: { sz: "12" } } },
                { value: purchases.brand, style: { font: { sz: "12" } } },
                { value: purchases.quotation_ref_no, style: { font: { sz: "12" } } },

            ])
        }
    ]


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };


    useEffect(() => {
        lodeUserData();
        lodeData();
    }, []);



    return (
        <div className='background'>
            <div className='container-fluid contentbox p-4'>

                <div className='row'>
                    <div className=' col-12'>
                        <div className='fleft mx-3'>
                            <a href="/purchasingmanagement">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft'>
                            <h2>Purchases</h2>
                        </div>
                    </div>

                </div>

                <div className=' col-12'>

                    <div className='row'>
                        <div>

                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Purchase ID...' name='purchase_id' value={purchase_id} onChange={handleInputChange}></input>

                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleTransNo}><icons.FaSearch /></button>
                                </div>
                            </div>

                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Item Code...' name='item_code' value={item_code} onChange={handleInputChange}></input>

                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleItemCode}><icons.FaSearch /></button>
                                </div>
                            </div>


                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="date" className="form-control" name='startdate' value={startdate} onChange={handleInputChange}></input>
                                    </div>
                                    <div className="form-outline">
                                        <input type="date" className="form-control" name='enddate' value={enddate} onChange={handleInputChange}></input>
                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleDate}><icons.FaFilter /></button>
                                </div>
                            </div>

                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Supplier ID...' name='supplier' value={supplier} onChange={handleInputChange}></input>

                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleSupplier}><icons.FaSearch /></button>
                                </div>
                            </div>

                            <div className='fleft mt-2 mx-3 mt-2'>
                                <button type='button' className='btn btn-success ' onClick={handleReset}>Reset</button>
                            </div>

                            <div className='input-group'>
                                <div className='mt-2 mx-3'>
                                    <div className="form-outline fleft ">
                                        <input type='text' className="form-control" placeholder='Enter File Name' name='filename' value={filename} onChange={handleInputChange}></input>
                                    </div>
                                    <ExcelFile
                                        filename={filename || "Purchases"}
                                        element={<button type="button" className="btn btn-success">Export Data</button>}>
                                        <ExcelSheet dataSet={DataSet} name="Items List" />
                                    </ExcelFile>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-3 mx-3'>

                        <div className='table-responsive me-3'>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Purchase<br />ID</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Item<br />Code</th>
                                        <th scope="col">Item<br />Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Unit<br />Price</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Supplier<br />ID</th>
                                        <th scope="col">Brand</th>
                                        <th scope="col">Quotation<br />Ref. No.</th>
                                        <th scope="col">User<br />ID</th>
                                        {userData.account_type === "admin" ? <th scope="col">Action</th> : null}

                                    </tr>
                                </thead>

                                <tbody>
                                    {data.map((purchases, index) => (
                                        <tr key={index}>
                                            <td>{purchases.purchase_id}</td>
                                            <td>{moment(purchases.date_time).format("YYYY/MM/DD")}</td>
                                            <td>{purchases.item_code}</td>
                                            <td>{purchases.item_name}</td>
                                            <td>{purchases.quntity}</td>
                                            <td>{purchases.unit_price}</td>
                                            <td>{purchases.amount}</td>
                                            <td className='d-flex'>
                                                <Link to={`/viewsuppliers/${purchases.supplier_id}`}>
                                                    {purchases.supplier_id}
                                                </Link>

                                            </td>
                                            <td>{purchases.brand}</td>
                                            <td className='d-flex'>
                                                <Link to={`/viewquotations/${purchases.quotation_ref_no}`}>
                                                    {purchases.quotation_ref_no}
                                                </Link>

                                            </td>
                                            <td>{purchases.user_id}</td>

                                            {userData.account_type === "admin" ?
                                                <td className='d-flex'>
                                                    <Link to={`/editpurchases/${purchases.purchase_id}`}>
                                                        <icons.FaEdit />
                                                    </Link>

                                                </td>
                                                : null}

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
