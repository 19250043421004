import React, { useState, useEffect } from 'react'
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import moment from 'moment';

const initialState = {

    ordering_status: "",
    for_approval: "",
    response_type: "",
    a_remarks: "",
    buy_supplier: "",
    buy_quntity: "",
    buy_sandup: "",
    buy_unitprice: "",
    buy_amount: ""
};



export default function Viewquotations() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);

    const lodeUserData = async () => {
        await axios.post(API_URL + "/profile", {},
            {
                withCredentials: true,
            })
            .then((resp) => setUserData({ ...resp.data[0] }))

    }

    useEffect(() => {
        lodeUserData();
    }, []);

    const qrn = window.location.href.split('/')[4];


    //Get Q Main Data
    const [QMainData, setQMainData] = useState([]);

    const lodeQMainData = async () => {
        const response = await axios.get(API_URL + `/getQSuppliers/${qrn}`);
        setQMainData(response.data[0]);

    }

    useEffect(() => {
        lodeQMainData();
    }, [qrn]);

    let quotation_ref_no = QMainData.quotation_ref_no;

    //Get Q Details Data

    const [QDetailsData, setQDetailsData] = useState([]);

    const lodeQDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationDetails/${quotation_ref_no}`);
        setQDetailsData(response.data);

    }

    //Get Q LP Details Data

    const [QLPDetailsData, setQLPDetailsData] = useState([]);

    const lodeQLPDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationLPDetails/${quotation_ref_no}`);
        setQLPDetailsData(response.data);

    }

    //Get Q TBP Details Data

    const [QTBPDetailsData, setQTBPDetailsData] = useState([]);

    const lodeQTBPDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationTBPDetails/${quotation_ref_no}`);
        setQTBPDetailsData(response.data);

    }

    //Get Q Amount sum

    const [supAmount, setSupAmount] = useState([]);
    const lodeSupAmount = async () => {
        const response = await axios.get(API_URL + `/getsupamount/${quotation_ref_no}`);
        setSupAmount(response.data[0]);
    }

    //Get Q Image Data

    const [qImageData, setQImageData] = useState([]);

    const getQImgData = async () => {
        const res = await axios.get(API_URL + `/getqimagedata/${quotation_ref_no}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setQImageData(res.data.data)

        } else {
            console.log("error")
        }
    }

    //Get R Image Data

    const [rImageData, setRImageData] = useState([]);

    const getRImgData = async () => {
        const res = await axios.get(API_URL + `/getrimagedata/${quotation_ref_no}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setRImageData(res.data.data)

        } else {
            console.log("error")
        }
    }


    useEffect(() => {
        lodeQDetailsData();
        lodeQLPDetailsData();
        lodeQTBPDetailsData();
        lodeSupAmount();
        getQImgData();
        getRImgData();
        getQMsgData();
    }, [quotation_ref_no]);

    //View Q Image Data

    const [viewQImageData, setViewQImageData] = useState([]);

    const viewQImgData = async (id) => {
        const res = await axios.get(API_URL + `/viewqimagedata/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setViewQImageData(res.data.data[0])

        } else {
            console.log("error")
        }
    }

    //View R Image Data

    const [viewRImageData, setViewRImageData] = useState([]);

    const viewRImgData = async (id) => {
        const res = await axios.get(API_URL + `/viewrimagedata/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setViewRImageData(res.data.data[0])

        } else {
            console.log("error")
        }
    }

    //View M Image Data

    const [viewMsgImageData, setViewMsgImageData] = useState([]);

    const viewMsgImgData = async (id) => {
        const res = await axios.get(API_URL + `/viewmsgimagedata/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setViewMsgImageData(res.data.data[0])

        } else {
            console.log("error")
        }
    }

    //Uplode Q Image Data

    const [file, setFile] = useState("");
    const [imgDescription, setImgDescription] = useState("");

    const setimgfile = (e) => {
        setFile(e.target.files[0])
    }

    const setImageDescription = (e) => {
        setImgDescription(e.target.value)
    }


    const addImageData = async (e) => {
        e.preventDefault();

        var formData = new FormData();
        formData.append("photo", file)
        formData.append("imgDescription", imgDescription);
        formData.append("quotation_ref_no", quotation_ref_no);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        if (!file) {
            toast.error("Please select image!");
        } else {
            const res = await axios.post(API_URL + "/uplodeqimage", formData, config);

            if (res.data.status === 201) {
                toast.success("Uploaded successfully!");
                getQImgData();
                setFile('');
                setImgDescription('');

            } else {
                console.log("error")
            }
        }

    }

    const [img, setImg] = useState("");
    const [msg, setMsg] = useState("");

    const setimagefile = (e) => {
        setImg(e.target.files[0])
    }

    const setMessage = (e) => {
        setMsg(e.target.value)
    }

    //Send msg

    const sendMsgData = async (e) => {
        e.preventDefault();

        var formData = new FormData();
        formData.append("image", img)
        formData.append("message", msg);
        formData.append("quotation_ref_no", quotation_ref_no);
        formData.append("user", userData.name);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        if (!img && !msg) {
            toast.error("Please enter message or select image!");
        } else {
            if (!img) {
                const res = await axios.post(API_URL + "/sendqmsgonly", formData, config);
                if (res.data.status === 201) {
                    toast.success("Send!");
                    getQMsgData();
                    setImg('');
                    setMsg('');

                } else {
                    console.log("error")
                }
            } else {
                const res = await axios.post(API_URL + "/sendqmsg", formData, config);

                if (res.data.status === 201) {
                    toast.success("Send!");
                    getQMsgData();
                    setImg('');
                    setMsg('');

                } else {
                    console.log("error")
                }
            }

        }

    }

    //Get Msg Data

    const [qMsgData, setQMsgData] = useState([]);

    const getQMsgData = async () => {
        const res = await axios.get(API_URL + `/getqmsgdata/${quotation_ref_no}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (res.data.status === 201) {
            setQMsgData(res.data.data)

        } else {
            console.log("error")
        }
    }

    useEffect(() => {
        getQMsgData();
    }, []);


    const [state, setState] = useState(initialState);

    const { ordering_status, for_approval, verify_type, response_type, a_remarks, buy_quantity, buy_sandup } = state;
    const res_user = userData.name;
    const res_userposition = userData.position;
    const bsup = buy_sandup.split(',');
    const buy_supplier = bsup[0];
    const buy_unitprice = bsup[1];
    const buy_amount = buy_quantity * buy_unitprice;

    const handleVerifySubmit = (e) => {
        e.preventDefault();
        if (!verify_type) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/updateverificationtype/${quotation_ref_no}`, {
                verify_type,
                res_user,
                res_userposition
            })
                .then(() => {
                    lodeQMainData();
                    lodeQDetailsData();
                    lodeQLPDetailsData();
                    lodeQTBPDetailsData();
                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }


    const handleChackSubmit = (e) => {
        e.preventDefault();
        if (!for_approval) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/updatecheckingstatus/${quotation_ref_no}`, {
                for_approval,
                res_user,
                res_userposition
            })
                .then(() => {
                    lodeQMainData();
                    lodeQDetailsData();
                    lodeQLPDetailsData();
                    lodeQTBPDetailsData();
                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const handleOrderSubmit = (e) => {
        e.preventDefault();
        if (!ordering_status) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/updateorderingstatus/${quotation_ref_no}`, {
                ordering_status,
                res_user

            })
                .then(() => {
                    lodeQMainData();
                    lodeQDetailsData();
                    lodeQLPDetailsData();
                    lodeQTBPDetailsData();
                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const handleApprovalTypeEdit = (e) => {
        e.preventDefault();
        if (!response_type) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/approvalTypeEdit/${quotation_ref_no}`,
                {
                    response_type,
                    res_user,
                    res_userposition
                })
                .then(() => {
                    setState({
                        response_type: "",
                        buy_sandup: ""
                    });
                    lodeQMainData();

                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const handleRemarkesSubmit = (e) => {
        e.preventDefault();
        if (!a_remarks) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/addremarkes/${quotation_ref_no}`,
                {

                    a_remarks

                })
                .then(() => {
                    setState({
                        a_remarks: "",
                        buy_sandup: ""
                    });
                    lodeQMainData();

                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const [viewBuyData, setViewBuyData] = useState([]);

    const viewBuyModelData = async (id) => {
        const response = await axios.get(API_URL + `/viewBuyData/${id}`);
        setViewBuyData(response.data[0]);
    }

    const [isPopupOpen, setPopupOpen] = useState(false);
    const buy_id = viewBuyData.id;

    const handleBuy = (e) => {
        e.preventDefault();
        if (!buy_supplier || !buy_quantity) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/addbuydetails/${buy_id}/${quotation_ref_no}`,
                {
                    buy_supplier,
                    buy_quantity,
                    buy_unitprice,
                    buy_amount,
                    res_user,
                    res_userposition


                })
                .then(() => {
                    setPopupOpen(false);
                    setState({
                        buy_supplier: "",
                        buy_quntity: "",
                        buy_amount: "",
                        buy_unitprice: "",
                        buy_sandup: ""
                    });

                    lodeQMainData();
                    lodeQDetailsData();
                    lodeQLPDetailsData();
                    lodeQTBPDetailsData();
                    lodeToApprovalQData();
                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const [resfile, setResFile] = useState("");
    const [resimgDescription, setResImgDescription] = useState("");

    const setResImgfile = (e) => {
        setResFile(e.target.files[0])
    }

    const setResImageDescription = (e) => {
        setResImgDescription(e.target.value)
    }


    const addResImageData = async (e) => {
        e.preventDefault();

        var formData = new FormData();
        formData.append("photo", resfile)
        formData.append("imgDescription", resimgDescription);
        formData.append("quotation_ref_no", quotation_ref_no);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
        if (!resfile) {
            toast.error("Please select image!");
        } else {
            const res = await axios.post(API_URL + "/uploderimage", formData, config);

            if (res.data.status === 201) {
                toast.success("Uploaded successfully!");
                getRImgData();
                setResFile('');
                setResImgDescription('');
            } else {
                console.log("error")
            }
        }

    }

    const [toApprovalQData, setToApprovalQData] = useState([]);
    const lodeToApprovalQData = async () => {
        const response = await axios.get(API_URL + "/getToApprovalQuotations");
        setToApprovalQData(response.data);
    }

    useEffect(() => {
        lodeToApprovalQData();
    }, []);

    const viewNextQuotation = (quotation_ref_no) => {

        navigate(`/viewquotations/${quotation_ref_no}`);
    };

    const currencyformatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>View Quotation</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='row'>
                        <div className='col-12'>
                            <div style={{ outline: "solid" }} className='mt-3 p-1' >
                                <div>
                                    <div className="text-center"> <h5>Associated Speciality Rubbers (PVT) Ltd. <br /> Quotation for Purchase Order</h5></div>
                                    <hr style={{ color: 'black', height: '1px', }} />
                                    <div className="row">
                                        <div className="fleft">
                                            <h6 className="fleft col-sm-6">Quotation Reference No : ASR/ {quotation_ref_no}</h6>
                                            <h6 className="fleft text-end col-sm-6">Date : {moment(QMainData.date).format("YYYY/MM/DD")}</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ color: 'black', height: '1px', }} />
                                <h5>Supplier quotations and details</h5>
                                <div className="row" >
                                    <div className='table-responsive'>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr className="align-top text-center">

                                                    {(userData.username === "arshad123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "MD" || QMainData.for_approval === "both")) ||
                                                        (userData.username === "shazni123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "Chairman" || QMainData.for_approval === "both")) ?
                                                        <th rowSpan="2" scope="col">Action</th> : ""}
                                                    <th rowSpan="2" scope="col">No</th>
                                                    <th rowSpan="2" scope="col">Item Code</th>
                                                    <th rowSpan="2" scope="col">Item Name</th>
                                                    <th rowSpan="2" scope="col">Brand</th>
                                                    <th rowSpan="2" scope="col">Use For</th>
                                                    <th rowSpan="2" scope="col">Request Person</th>
                                                    <th rowSpan="2" scope="col">Extra Note</th>
                                                    <th rowSpan="2" scope="col">Unit</th>
                                                    <th rowSpan="2" scope="col">Existing Stock</th>
                                                    <th colSpan="4" scope="col">Unit Price</th>
                                                    <th rowSpan="2" scope="col">Quntity</th>
                                                    <th colSpan="4" scope="col">Amount</th>

                                                </tr>
                                                <tr className="text-center">
                                                    <th scope="col">{QMainData.qsupplier01}</th>
                                                    <th scope="col">{QMainData.qsupplier02}</th>
                                                    <th scope="col">{QMainData.qsupplier03}</th>
                                                    <th scope="col">{QMainData.qsupplier04}</th>
                                                    <th scope="col">{QMainData.qsupplier01}</th>
                                                    <th scope="col">{QMainData.qsupplier02}</th>
                                                    <th scope="col">{QMainData.qsupplier03}</th>
                                                    <th scope="col">{QMainData.qsupplier04}</th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {QDetailsData.map((qDetails, index) => (
                                                    <tr key={index}>
                                                        {(userData.username === "arshad123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "MD" || QMainData.for_approval === "both")) ||
                                                            (userData.username === "shazni123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "Chairman" || QMainData.for_approval === "both")) ?
                                                            <td className="text-center"><Popup trigger={<button type="button" className="btnNoBackground"><icons.FaShoppingCart size='1rem' /></button>} position="right center" open={isPopupOpen}
                                                                onClose={() => setPopupOpen(false)}>
                                                                <div>  <form >
                                                                    <select className="form-control form-control-sm" name="buy_sandup" value={buy_sandup || ""} onChange={handleInputChange} onFocus={() => viewBuyModelData(qDetails.id)}>
                                                                        <option value="">Select Supplier...</option>
                                                                        <option value={[QMainData.qsupplier01, viewBuyData.qunit_price01]}>{QMainData.qsupplier01}</option>
                                                                        <option value={[QMainData.qsupplier02, viewBuyData.qunit_price02]}>{QMainData.qsupplier02}</option>
                                                                        <option value={[QMainData.qsupplier03, viewBuyData.qunit_price03]}>{QMainData.qsupplier03}</option>
                                                                        <option value={[QMainData.qsupplier04, viewBuyData.qunit_price04]}>{QMainData.qsupplier04}</option>
                                                                    </select>

                                                                    <input type='text' placeholder='Quantity to be purchased' className="form-control form-control-sm mt-2" name='buy_quantity' value={buy_quantity || ""} onChange={handleInputChange}></input>
                                                                    <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleBuy}>Buy</button>
                                                                </form></div>
                                                            </Popup></td> : ""}
                                                        <th scope='row'>{index + 1}</th>
                                                        <td>{qDetails.item_code}</td>
                                                        <td>{qDetails.item_name}</td>
                                                        <td>{qDetails.qbrand}</td>
                                                        <td>{qDetails.use_for}</td>
                                                        <td>{qDetails.reqest_person}</td>
                                                        <td>{qDetails.extra_note}</td>
                                                        <td>{qDetails.unit}</td>
                                                        <td className="text-center">{qDetails.stock}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.qunit_price01)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.qunit_price02)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.qunit_price03)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.qunit_price04)}</td>
                                                        <td className="text-center">{qDetails.quntity}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.amount01)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.amount02)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.amount03)}</td>
                                                        <td className="text-end">{currencyformatter.format(qDetails.amount04)}</td>


                                                    </tr>
                                                ))}

                                                <tr>
                                                    {userData.username === "shazni123" || userData.username === "arshad123" ?
                                                        <th colSpan="15">Total</th> : <th colSpan="14">Total</th>}
                                                    <th className="text-end">{currencyformatter.format(supAmount.sup01sum)}</th>
                                                    <th className="text-end">{currencyformatter.format(supAmount.sup02sum)}</th>
                                                    <th className="text-end">{currencyformatter.format(supAmount.sup03sum)}</th>
                                                    <th className="text-end">{currencyformatter.format(supAmount.sup04sum)}</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <dt className="col-sm-3">Last Purchase Details:</dt>
                                <div className='table-responsive'>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="text-center" >
                                                <th scope="col">No</th>
                                                <th scope="col">Item Code</th>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Purchases ID</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Supplier</th>
                                                <th scope="col">Brand</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        {QLPDetailsData.length > 0 ?
                                            <tbody>
                                                {QLPDetailsData.map((qLPDetails, index) => (
                                                    qLPDetails.lpquntity !== 0 && qLPDetails.lpquntity !== null ?
                                                        <tr key={index}>
                                                            <th scope='row'>{index + 1}</th>
                                                            <td>{qLPDetails.item_code}</td>
                                                            <td>{qLPDetails.item_name}</td>
                                                            <td>{qLPDetails.purchase_id}</td>
                                                            <td>{moment(qLPDetails.lpdate).format("YYYY/MM/DD")}</td>
                                                            <td>{qLPDetails.lpsupplier}</td>
                                                            <td>{qLPDetails.lpbrand}</td>
                                                            <td className="text-center">{qLPDetails.lpquntity}</td>
                                                            <td className="text-end">{currencyformatter.format(qLPDetails.lpunit_price)}</td>
                                                            <td className="text-end">{currencyformatter.format(qLPDetails.lpunit_price * qLPDetails.lpquntity)}</td>
                                                        </tr> : 'No data'
                                                ))}
                                            </tbody> : <tbody><tr><td colSpan="10">No Record Found</td></tr></tbody>}
                                    </table>
                                </div>


                                <div>
                                    <dl className="row">
                                        <dt className="col-lg-1 col-sm-2">Remarks:</dt>
                                        <dd className="col-sm-9">{QMainData.remarks}</dd>
                                    </dl>
                                </div>

                                {userData.username !== "dilini" && qImageData.length > 0 ?
                                    <dl className="row">
                                        <dt className="col-sm-2">Images:</dt>
                                        <dd className="col-sm-10">

                                            <div className='d-flex align-items-center'>

                                                {qImageData.length > 0 ?
                                                    qImageData.map((el, index) => (


                                                        <div key={index} className="card" style={{ width: "11rem", height: "10rem" }} >
                                                            <img src={API_URL + `/Qimguploads/${el.userimg}`} className="card-img-top" alt="..." style={{ height: "7rem" }} />
                                                            <div className="card-footer">
                                                                <button type="button" className='btnNoBackground fright' data-bs-toggle="modal" data-bs-target="#viewQImageModal" onClick={() => viewQImgData(el.id)}><icons.FaEye color='#0B5ED7' /></button>
                                                                {el.imgDescription !== "" ? <icons.FaInfoCircle /> : ""}
                                                            </div>
                                                        </div>
                                                    )) : ""
                                                }&nbsp;&nbsp; {userData.account_type === "user" && QMainData.response_type === "pending" ?
                                                    <button type="button" className='btn btn-primary btn-sm' data-bs-toggle="modal" data-bs-target="#viewUplodeQuotationImage" ><icons.FaUpload /></button>
                                                    : null}
                                            </div>
                                        </dd>
                                    </dl> : null}

                                <hr style={{ color: 'black', height: '3px', }} />
                                <h5>Quotation approval</h5>

                                <div className="row">
                                    <div className="fleft col-sm-6" >
                                        <dl className="row">
                                            <dt className="col-lg-3 col-sm-4">Approval Type:</dt>
                                            <dd className="col-sm-4">{QMainData.response_type} &nbsp; {(userData.username === "arshad123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "MD" || QMainData.for_approval === "both")) ||
                                                (userData.username === "shazni123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "Chairman" || QMainData.for_approval === "both")) ?
                                                <button type="button" className="btnNoBackground" data-bs-toggle="modal" data-bs-target="#viewApprovalTypeEdit" ><icons.FaEdit /></button>
                                                : null}</dd>
                                        </dl>
                                    </div>

                                    <div className="fleft col-sm-6" >

                                        <dl className="row">
                                            <dt className="col-lg-3 col-sm-4">Response Date:</dt>
                                            {QMainData.response_date !== null ?
                                                <dd className="col-sm-4">{moment(QMainData.response_date).format("YYYY/MM/DD")}</dd> : null}
                                            {QMainData.response_date === null ?
                                                <dd className="col-sm-4">-</dd> : null}
                                        </dl>
                                    </div>
                                </div>

                                {QMainData.response_type === "accept" ? <div>

                                    <dl>
                                        <dt className="col-sm-6">Details to purchase:</dt>
                                        <dd className="col-sm-12">
                                            <div className='table-responsive me-3'>
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr className="text-center" >
                                                            <th scope="col">No</th>
                                                            <th scope="col">Item code</th>
                                                            <th scope="col">Item Name</th>
                                                            <th scope="col">Brand</th>
                                                            <th scope="col">Supplier to be purchased</th>
                                                            <th scope="col">Quantities to be purchased</th>
                                                            <th scope="col">Unit Price</th>
                                                            <th scope="col">Total</th>
                                                        </tr>
                                                    </thead>
                                                    {QTBPDetailsData.length > 0 ?
                                                        <tbody>
                                                            {QTBPDetailsData.map((qTBPDetails, index) => (
                                                                <tr key={index}>
                                                                    <th scope='row'>{index + 1}</th>
                                                                    <td>{qTBPDetails.item_code}</td>
                                                                    <td>{qTBPDetails.item_name}</td>
                                                                    <td>{qTBPDetails.qbrand}</td>
                                                                    <td>{qTBPDetails.buy_supplier}</td>
                                                                    <td className="text-center">{qTBPDetails.buy_quantity}</td>
                                                                    <td className="text-end">{currencyformatter.format(qTBPDetails.buy_unitprice)}</td>
                                                                    <td className="text-end">{currencyformatter.format(qTBPDetails.buy_amount)}</td>
                                                                </tr>
                                                            ))}
                                                            <tr>
                                                                <th colSpan="7">Total</th>
                                                                <th className="text-end">{currencyformatter.format(supAmount.buysum)}</th>
                                                            </tr>
                                                        </tbody> : "No Record Found"}
                                                </table>
                                            </div>
                                        </dd>
                                    </dl>

                                </div> : ""}

                                <dl className="row">
                                    <dt className="col-lg-1 col-sm-2">Remarks:</dt>
                                    <dd className="col-lg-11 col-sm-10">{QMainData.a_remarks} &nbsp; {(userData.username === "arshad123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "MD" || QMainData.for_approval === "both")) ||
                                        (userData.username === "shazni123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "Chairman" || QMainData.for_approval === "both")) ?
                                        <button type="button" className="btnNoBackground" data-bs-toggle="modal" data-bs-target="#viewAddRemarks" ><icons.FaEdit /></button>
                                        : null}</dd>

                                </dl>

                                <dl className="row">
                                    <dt className="col-lg-1 col-sm-2">Images:</dt>

                                    <dd className="col-lg-11 col-sm-10">

                                        <div className='d-flex align-items-center'>

                                            {rImageData.length > 0 ?
                                                rImageData.map((el, index) => (


                                                    <div key={index} className="card" style={{ width: "11rem", height: "10rem" }}>

                                                        <div >
                                                            <img src={API_URL + `/Rimguploads/${el.userimg}`} className="card-img-top" alt="..." style={{ height: "7rem" }} />
                                                        </div>
                                                        <div className="card-footer">

                                                            {el.imgDescription !== "" ? <icons.FaInfoCircle /> : ""}
                                                            <button type="button" className='btnNoBackground fright' data-bs-toggle="modal" data-bs-target="#viewRImageModal" onClick={() => viewRImgData(el.id)}><icons.FaEye color='#0B5ED7' /></button>
                                                        </div>
                                                    </div>
                                                )) : ""
                                            } &nbsp;&nbsp; {(userData.username === "arshad123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "MD" || QMainData.for_approval === "both")) ||
                                                (userData.username === "shazni123" && QMainData.ordering_status === "pending" && (QMainData.for_approval === "Chairman" || QMainData.for_approval === "both")) ?
                                                <button type="button" className="btnNoBackground" data-bs-toggle="modal" data-bs-target="#viewUplodeApprovalImage" ><icons.FaUpload /></button>
                                                : null}
                                        </div>
                                    </dd>
                                </dl>

                                <hr style={{ color: 'black', height: '3px', }} />
                                <h5>Ordering details</h5>

                                <div className="row">
                                    <div className="fleft col-sm-6" >
                                        <dl className="row">
                                            <dt className="col-lg-3 col-sm-4">Ordering Status:</dt>
                                            <dd className="col-sm-2">{QMainData.ordering_status}</dd>
                                        </dl>
                                    </div>

                                    <div className="fleft col-sm-6" >
                                        <dl className="row">
                                            <dt className="col-lg-3 col-sm-4">Ordering Date:</dt>
                                            {QMainData.order_date !== null ?
                                                <dd className="col-sm-2">{moment(QMainData.order_date).format("YYYY/MM/DD")}</dd> : null}
                                            {QMainData.order_date === null ?
                                                <dd className="col-sm-4">-</dd> : null}
                                        </dl>
                                    </div>
                                </div>

                                <hr style={{ color: 'black', height: '3px', }} />

                                <div className="row">
                                    <div >
                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Prepared by:</dt>
                                                <p>{QMainData.prepared_by}<br />{QMainData.prepared_position}<br />{moment(QMainData.date).format("YYYY/MM/DD")}</p>
                                            </dl>
                                        </div>
                                        {QMainData.for_verify !== "noneed" ?
                                            <div className="fleft col-sm-3">
                                                <dl>
                                                    <dt>Verified by:</dt>
                                                    {QMainData.verify_by !== null ?
                                                        <p>{QMainData.verify_by}<br />{QMainData.verify_position}<br />{moment(QMainData.verify_date).format("YYYY/MM/DD")}</p> : "............................."}
                                                </dl>
                                            </div> : ""}

                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Checked by:</dt>
                                                {QMainData.checked_by !== null ?
                                                    <p>{QMainData.checked_by}<br />{QMainData.checked_position}<br />{moment(QMainData.checked_date).format("YYYY/MM/DD")}</p> : "............................."}
                                            </dl>
                                        </div>

                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Approved by:</dt>
                                                {QMainData.responsed_by !== null ?
                                                    <p>{QMainData.responsed_by}<br /> {QMainData.responsed_position} <br /> {moment(QMainData.response_date).format("YYYY/MM/DD")}</p> : "............................."}
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {userData.account_type === "user" && QMainData.response_type === "accept" ?
                    <div className='row'>
                        <div className='col-11 col-md-6 col-sm-9 mt-2'>

                            <form onSubmit={handleOrderSubmit}>

                                <div>
                                    <h5 className="mt-2">Ordering  Status:</h5>
                                    <select name="ordering_status" className="form-control" value={ordering_status} onChange={handleInputChange}>
                                        <option value="">Select Ordering  Status...</option>
                                        <option value="ordered">Ordered</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>

                                <button type='submit' className='btn btn-success mt-2' >Save</button>

                            </form>
                        </div>
                    </div> : null}



                {(userData.username === QMainData.for_verify) ?
                    <div className='row'>
                        <div className='col-11 col-md-6 col-sm-9 mt-2'>

                            <form onSubmit={handleVerifySubmit}>

                                <div>
                                    <h5 className="mt-2">Verify Quotation:</h5>
                                    <select name="verify_type" className="form-control" value={verify_type} onChange={handleInputChange}>
                                        <option value="">Select Verification Type...</option>
                                        <option value="accept">Accept</option>
                                        <option value="pending">Pending</option>

                                    </select>
                                </div>

                                <button type='submit' className='btn btn-success mt-2' >Save</button>

                            </form>
                        </div>
                    </div> : null}

                {userData.account_type === "admin" && userData.username === "ASRD1234" && QMainData.response_type === "pending" && (QMainData.verify_type === "accept" || QMainData.for_verify === "noneed") ?
                    <div className='row'>
                        <div className='col-11 col-md-6 col-sm-9 mt-2'>

                            <form onSubmit={handleChackSubmit}>

                                <div>
                                    <h5 className="mt-2">To send for approval:</h5>
                                    <select name="for_approval" className="form-control" value={for_approval} onChange={handleInputChange}>
                                        <option value="">Select who to send to for approval...</option>
                                        <option value="MD">to the Managing Director</option>
                                        <option value="Chairman">to the Chairman</option>
                                        <option value="both">to the Both</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>

                                <button type='submit' className='btn btn-success mt-2' >Save</button>

                            </form>
                        </div>
                    </div> : null}

                <div className="row mt-3 ">
                    <div className='col-12'>
                        <Link className='btn btn-sm btn-primary mt-3 fleft' to={`/printquotations/${quotation_ref_no}`}>Save or Print</Link>
                        <button type="button" className="btn btn-sm btn-info fright" data-bs-toggle="modal" data-bs-target="#msgModal">
                            <icons.FaComment size='2em' />
                        </button>
                    </div>
                </div>
                {toApprovalQData.length !== 0 && (userData.username === "arshad123" || userData.username === "shazni123") ?
                    <div className="row mt-3 ">
                        <div className='col-12'>
                            <h5>Quotations to be approved</h5>
                            {
                                toApprovalQData.map((quotations, index) => (
                                    <button type="button" className="btn btn-secondary fleft me-2" key={index} onClick={() => viewNextQuotation(quotations.quotation_ref_no)}>{quotations.quotation_ref_no}</button>
                                ))
                            }
                        </div>
                    </div> : ""}


                <div className="modal fade" id="msgModal" tabIndex="-1" aria-labelledby="msgModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Messages</h5>
                                <button type="button" className="btn btn btn-outline-secondary btn-sm mx-4" onClick={getQMsgData}><icons.FaRedoAlt /></button>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                {qMsgData.map((el, index) => (
                                    <div key={index} className="card mt-3">
                                        <div className="card-header">
                                            <p className="card-text">{el.user}</p>
                                        </div>
                                        <div className="card-body">
                                            {el.img ?
                                                <div>
                                                    <img src={API_URL + `/MsgImgUplode/${el.img}`} className="card-img-top" alt="..." style={{ width: "22rem", height: "15rem" }} />
                                                    <button type="button" className='btnNoBackground fright' data-bs-toggle="modal" data-bs-target="#viewMsgImageModal" onClick={() => viewMsgImgData(el.id)}><icons.FaEye color='#0B5ED7' /></button>
                                                </div>
                                                : null}
                                            <p className="card-text">{el.msg}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="mx-2 mb-2">
                                <hr style={{ color: 'black', height: '1px' }} />
                                <form>
                                    <input type='file' className="form-control" name="image" onChange={setimagefile}></input>

                                    <input type='text' placeholder='Enter Message' className="form-control mt-3" name="message" value={msg} onChange={setMessage}></input>

                                    <div> <button className='btn btn-success mt-3' type="submit" onClick={sendMsgData}>Send</button> </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="viewQImageModal" tabIndex="-1" aria-labelledby="viewQImageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <div className="card">
                                    <div className="card-body">
                                        <img src={API_URL + `/Qimguploads/${viewQImageData.userimg}`} className="card-img-top" alt="..." />
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <p>{viewQImageData.imgDescription}</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="viewRImageModal" tabIndex="-1" aria-labelledby="viewRImageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <div className="card">
                                    <div className="card-body">
                                        <img src={API_URL + `/Rimguploads/${viewRImageData.userimg}`} className="card-img-top" alt="..." />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <p>{viewRImageData.imgDescription}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="viewMsgImageModal" tabIndex="-1" aria-labelledby="viewMsgImageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#msgModal"></button>
                            </div>
                            <div className="modal-body">

                                <div className="card">
                                    <div className="card-body">
                                        <img src={API_URL + `/MsgImgUplode/${viewMsgImageData.img}`} className="card-img-top" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Approval Type Edit */}

                <div className="modal fade" id="viewApprovalTypeEdit" tabIndex="-1" aria-labelledby="viewApprovalTypeEditModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mt-2">Edit Approval Type:</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleApprovalTypeEdit}>

                                    <div>
                                        <select name="response_type" className="form-control" value={response_type} onChange={handleInputChange}>
                                            <option value="">Select Response Type...</option>
                                            <option value="accept">Accept</option>
                                            <option value="reject">Reject</option>
                                            <option value="pending">Pending</option>
                                        </select>
                                        <button type='submit' className='btn btn-success mt-2' data-bs-dismiss="modal">Update</button>
                                    </div >
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Approval Image Uplode */}

                <div className="modal fade" id="viewUplodeApprovalImage" tabIndex="-1" aria-labelledby="viewUplodeApprovalImageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mt-2">Uplode Image:</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form>

                                    <input type='file' className="form-control" name="photo" onChange={setResImgfile}></input>

                                    <input type='text' placeholder='Enter Image Description' className="form-control mt-3" name="imgDescription" value={resimgDescription} onChange={setResImageDescription}></input>

                                    <div> <button className='btn btn-success mt-3' data-bs-dismiss="modal" type="submit" onClick={addResImageData}>Uplode</button> </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Add remarks */}

                <div className="modal fade" id="viewAddRemarks" tabIndex="-1" aria-labelledby="viewAddRemarksModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mt-2">Remarks:</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form onSubmit={handleRemarkesSubmit}>

                                    <div className="mt-2">
                                        <textarea type='text' name='a_remarks' placeholder='Enter Remarks' className="form-control" value={a_remarks || ""} onChange={handleInputChange}></textarea>
                                    </div>

                                    <button type='submit' className='btn btn-success mt-2' data-bs-dismiss="modal" >Save</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Quotation Image Uplode */}

                <div className="modal fade" id="viewUplodeQuotationImage" tabIndex="-1" aria-labelledby="viewUplodeQuotationImageModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="mt-2">Uplode Image:</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <form>
                                    <input type='file' className="form-control" name="photo" onChange={setimgfile}></input>

                                    <input type='text' placeholder='Enter Image Description' className="form-control mt-3" name="imgDescription" value={imgDescription} onChange={setImageDescription}></input>

                                    <div> <button className='btn btn-success mt-3' type="submit" data-bs-dismiss="modal" onClick={addImageData}>Uplode</button> </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <ToastContainer />
        </div >
    )
}
