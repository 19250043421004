import React, { useState, useEffect } from 'react'
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';

export default function Logreport() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    // const [userData, setuserData] = useState([]);

    // useEffect(() => {
    //     const lodeUserData = async () => {
    //         await axios.post(API_URL + "/profile", {},
    //             {
    //                 withCredentials: true,
    //             })
    //             .then((resp) => setuserData({ ...resp.data[0] }))

    //     };
    //     lodeUserData();
    // }, []);

    // // if (userData.account_type === "user") {
    // //     navigate("/");
    // // }

    const [data, setData] = useState([]);
    const lodeData = async () => {
        const response = await axios.get(API_URL + "/alllogs");
        setData(response.data);
    }
    
    useEffect(() => {
        lodeData();
    }, []);

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12 mt-2'>
                        <div className='fleft'>
                            <a href="/dashboard">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Log Report</h2>
                        </div>
                    </div>
                </div>

                <div className=' col-12'>
                    <div className='row'>
                        <div className='mt-3 mx-3'>

                            <div className='table-responsive me-3'>
                                <table className="table table-dark table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">User ID</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Description</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.map((log, index) => (
                                            <tr key={index}>
                                                <td>{log.log_id}</td>
                                                <td>{moment(log.log_datetime).format("YYYY/MM/DD h:mm:ss")}</td>
                                                <td>{log.user_id}</td>
                                                <td>{log.user_fname}</td>
                                                <td>{log.log_description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
