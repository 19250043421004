import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


const initialState = {
    item_code: "",
    item_name: "",
    quntity: "",
    unit_price: "",
    amount: "",
    use_for: "",
    discription: ""

}
export default function EditAdjustPTransaction() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const [state, setState] = useState(initialState);

    const { transactions_number } = useParams();
    const { item_code, item_name, quntity, unit_price, discription } = state;
    const amount = quntity * unit_price;
    const user_id = userData.user_id;
    const user_fname = userData.name;

    useEffect(() => {
        axios.get(API_URL + `/oneAdjustPTransaction/${transactions_number}`)
            .then((resp) => setState({ ...resp.data[0] }))
    }, [transactions_number])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name || !quntity || !unit_price) {
            toast.error("Please fill in all data!");
        } else {
            if (
                window.confirm("Are you sure?")
            ) {
                axios.patch(API_URL + `/updateAdjustPTransaction/${transactions_number}`, {

                    item_code,
                    item_name,
                    quntity,
                    unit_price,
                    amount,
                    discription,
                    user_fname,
                    user_id
                })
                    .then(() => {

                        toast.success("Success!");
                    })

                    .catch((err) => toast.error(err.response.data));
            }
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <a href="/adjustptransactions">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Edit Transaction</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>

                            <div>
                                <h5 className="mt-2">Item Code:</h5>
                                <input type='text' readOnly placeholder='Enter Item Code' className="form-control" name='item_code' value={item_code} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Item Name:</h5>
                                <input type='text' readOnly placeholder='Check Item Name' className="form-control" name='item_name' value={item_name} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Item Quantity:</h5>
                                <input type='text' placeholder='Enter Item Quantity' className="form-control" name='quntity' value={quntity} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Unit Price:</h5>
                                <input type='text' placeholder='Enter Unit Price' className="form-control" name='unit_price' value={unit_price} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Amount:</h5>
                                <input type='text' placeholder='Check Amount' className="form-control" name='amount' value={amount} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Description:</h5>
                                <input type='text' placeholder='Enter Discription' className="form-control" name='discription' value={discription} onChange={handleInputChange}></input>
                            </div>

                            <button type='submit' className='btn btn-success mt-2' >Update</button>
                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
