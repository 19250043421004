import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


export default function Useracc() {
    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user") {
        navigate("/");
    }


    const [data, setData]=useState([]);

    const lodeData=async()=>{
        const response=await axios.get(API_URL + "/allUsers");
        setData(response.data);
    }

    useEffect(()=>{
        lodeData();
    },[]);

    function setNoshowSuper(){
        if(userData.account_type==="super_admin"){
          return false;
        }else{
          return true;
        }
      }
    const noshowSuper=setNoshowSuper();

    

    return (
        
        <div className='background'>
            <div className='contentbox p-1'>
                <div className='row'>
                    <div className='col-3'>
                        <NavBar/>
                    </div>
                    

                    <div className=' col-9'>
                        <div className='row mt-2'>
                            <div className='col-9'>
                                <h2>User Accounts</h2>
                                
                            </div>
                        </div>
                        {noshowSuper?
                        <div className='mt-3'>  
                            <div><a href='/newuser' className='btn btn-outline-success' role='button'><icons.FaUsers/> Create New User</a></div>
                        </div>:null}

                        <div className='mt-3'>
                      
                            <div className='table-responsive me-3'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">User ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Position</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Contact Number</th>
                                        <th scope="col">Account Type</th>
                                        {noshowSuper? <th scope="col">Action</th>:null}
                                    </tr>
                                </thead>
                            
                                <tbody>
                                {data.map((users,index)=>(
                                        <tr key={index}>
                                        
                                           
                                                 <td>{users.user_id}</td>
                                                <td>{users.name}</td>
                                                <td>{users.position}</td>
                                                <td>{users.email}</td>
                                                <td>{users.contact_no}</td>
                                                <td>{users.account_type}</td>
                                                
                                               
                                                {noshowSuper?
                                                <td className='d-flex'>
                                                    
                                                    <Link to={`/edituser/${users.user_id}`}>
                                                    <icons.FaEdit/>
                                                    </Link>
                                                    
                                                </td>
                                                :null}
                                        
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
  }
