import React, { useState, useEffect } from 'react'
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

export default function Quotationastosend() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);

    useEffect(() => {

        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }

        lodeUserData();
    }, []);

    const [toSendQData, setToSendQData] = useState([]);
    const lodeToSendQData = async () => {
        const response = await axios.get(API_URL + "/getToSendQuotations");
        setToSendQData(response.data);
    }

    const handleEdit = (quotation_ref_no, response_type) => {

        if (response_type === "pending") {
            navigate(`/editquotation/${quotation_ref_no}`);
        } else {
            toast.error("Editing is not allowed")
        }

    };

    const handleview = (quotation_ref_no) => {
        navigate(`/viewquotations/${quotation_ref_no}`);
    };

    const handleSendStatus = async (quotation_ref_no) => {
        try {
          await axios.patch(API_URL + `/updatesendstatus/${quotation_ref_no}`);
          toast.success("Success!");
          lodeToSendQData();
        } catch (error) {
          console.error("Error sending status:", error);
          toast.error("Error sending status. Please try again later.");
        }
      }

    useEffect(() => {
        lodeToSendQData();
    }, []);

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-4'>
                <div className='row'>

                    <div className='row'>
                        <div className=' col-12'>
                            <div className='fleft mx-3'>
                                <h2>Quotations</h2>
                            </div>

                            <div className='fright'>
                                <a href={"/dashboard"}>
                                    <icons.FaHome size='2.5em' color='#274F6A' />
                                </a>
                            </div>
                        </div>

                    </div>
                    <div className='row mt-2'>
                        <div className='col-12'>

                            <div className="fleft mx-3 btn-group col-9" role="group" >
                                <a href='/quotationstosend' role='button' className="btn btn-primary">To Send</a>
                                <a href='/quotationstoverify' role='button' className="btn btn-outline-primary">To verify</a>
                                <a href='/quotationstocheck' role='button' className="btn btn-outline-primary">To Check</a>
                                <a href='/quotationstoapproval' role='button' className="btn btn-outline-primary">To Approval</a>
                                <a href='/quotationstoorder' role='button' className="btn btn-outline-primary">To Order</a>
                                <a href='/quotationsall' role='button' className="btn btn-outline-primary">Complete</a>
                            </div>

                            <div className="fright btn-group col-2">
                                {userData.account_type === "user" ?
                                    <a href='/addquotation' className='btn btn-outline-success' role='button'><icons.FaPlusCircle /> Add Quotation </a>
                                    : null}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='row'>
                            <div className=' col-12'>
                                <div className='row'>
                                    <div className='mt-3 mx-3'>

                                        <div className='table-responsive me-3'>
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Quotation<br />Ref. No.</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Response<br />Type</th>
                                                        <th scope="col">Action</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {toSendQData.map((quotation, index) => (
                                                        <tr key={index}>
                                                            <td>{quotation.quotation_ref_no}</td>
                                                            <td>{moment(quotation.date).format("YYYY/MM/DD")}</td>
                                                            <td>{quotation.response_type}</td>

                                                            <td className='d-flex'>
                                                                <button type="button" className='btnNoBackground' onClick={() => handleview(quotation.quotation_ref_no)}><icons.FaEye /></button>

                                                                &nbsp; &nbsp;&nbsp;&nbsp;

                                                                {userData.account_type === "admin" || userData.account_type === "user" ?
                                                                    <button type="button" className='btnNoBackground' onClick={() => handleEdit(quotation.quotation_ref_no, quotation.response_type)}><icons.FaEdit /></button>
                                                                    : null}
                                                                &nbsp; &nbsp;&nbsp;&nbsp;
                                                                <button type="button" className="btn btn-sm btn-secundry" onClick={() => handleSendStatus(quotation.quotation_ref_no)}><icons.FaArrowCircleRight /></button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div >
    )
}
