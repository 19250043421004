import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';


export default function ViewPO() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });


    const prn = window.location.href.split('/')[4];


    //Get PO Main Data
    const [poMainData, setPOMainData] = useState([]);

    const lodePOMainData = async () => {
        const response = await axios.get(API_URL + `/getPOSuppliers/${prn}`);
        setPOMainData(response.data[0]);

    }

    useEffect(() => {
        lodePOMainData();
    }, [prn]);

    let po_ref_no = poMainData.po_ref_no;

    //Get PO Details Data

    const [poDetailsData, setPODetailsData] = useState([]);
    //console.log(poDetailsData)

    const lodePODetailsData = async () => {
        const response = await axios.get(API_URL + `/getPODetails/${po_ref_no}`);
        setPODetailsData(response.data);
    }

    //Get PO Amount sum

    const [poSumAmount, setPOSumAmount] = useState([]);
    const lodePOSumAmount = async () => {
        const response = await axios.get(API_URL + `/getposumamount/${po_ref_no}`);
        setPOSumAmount(response.data[0]);
    }

    //console.log(poSumAmount)

    useEffect(() => {
        lodePODetailsData();
        lodePOSumAmount();
    }, [po_ref_no]);

    let deliveryaddress = poMainData.delivery_address || "-,-,-,-"
    let da = deliveryaddress.split(',');
    let nettotal = (poSumAmount.pogrosssum + (poMainData.transport) - (poMainData.discount))


    const currencyFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `PO ${po_ref_no}`,
    });

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>

                        <div className='fleft'>
                            <h5>View Purchase Order</h5>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='1.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12' ref={componentRef}>
                        <div className="card p-5" style={{ fontSize: '12px' }}>
                            <div className="card-header">
                                <div style={{ textAlign: "center" }}>
                                    <h4 style={{ marginBottom: '0' }}>Associated Speciality Rubbers (PVT) Ltd.</h4>
                                    <h5 style={{ marginTop: '0' }}>Purchase Order</h5>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <span>P/O Reference No : {po_ref_no}</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span>Date : {moment(poMainData.date).format("YYYY/MM/DD")}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-3">
                                {/* Supplier Information */}
                                <div className="col-md-7">
                                    <table className="table table-sm table-borderless table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-25">To</th>
                                                <td>
                                                    <strong>{poMainData.supplier_name}</strong><br />
                                                    {poMainData.supplier_address}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Att.</th>
                                                <td>
                                                    {poMainData.att_name}<br />
                                                    {poMainData.att_contact_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Acc. No.</th>
                                                <td>{poMainData.acc}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                {/* Purchase Order Summary */}
                                <div className="col-md-5">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-40">S VAT</th>
                                                <td>{poMainData.svat}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Pay Term</th>
                                                <td>30 days</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Pay Mode</th>
                                                <td>Fund Transfer</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Bus. Reg.</th>
                                                <td>PV3704</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Q Ref. No.</th>
                                                <td>{poMainData.quotation_ref_no}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Delivery Details */}
                                <div className="col-md-7">
                                    <table className="table table-sm table-borderless">
                                        <tbody>
                                            <tr>
                                                <th className="w-25">Delivery Address</th>
                                                <td>{da[0]},<br />{da[1]},<br />{da[2]}, {da[3]}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Contact</th>
                                                <td>0774775615</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Delivery Date</th>
                                                <td>{moment(poMainData.delivery_date).format("YYYY/MM/DD")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                
                                {/* Supplier Reference Details */}
                                <div className="col-md-5">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-40">Sup. Ref. Type</th>
                                                <td>{poMainData.supplier_ref_type}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Sup. Ref No.</th>
                                                <td>{poMainData.supplier_ref}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Sup. Ref. Date</th>
                                                <td>{moment(poMainData.supplier_refdate).format("YYYY/MM/DD")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Purchase Order Items */}
                                <div className="col-12">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr className="table-primary text-center">
                                                <th className="w-10">No</th>
                                                <th>Item Name</th>
                                                <th className="w-10">Unit</th>
                                                <th className="w-10">Quantity</th>
                                                <th className="w-10">Unit Price</th>
                                                <th className="w-1">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {poDetailsData.map((poDetails, index) => (
                                                <tr key={index}>
                                                    <th>{index + 1}</th>
                                                    <td>{poDetails.item_name}</td>
                                                    <td>{poDetails.unit}</td>
                                                    <td className="text-center">{poDetails.quntity}</td>
                                                    <td className="text-end">{currencyFormatter.format(poDetails.unit_price)}</td>
                                                    <td className="text-end">{currencyFormatter.format(poDetails.total)}</td>
                                                </tr>
                                            ))}

                                            {/* Purchase Order Summary */}
                                            <tr>
                                                <td rowSpan="6" colSpan="3">

                                                </td>
                                                <th colSpan="2">Gross Total</th>
                                                <th className="text-end">{currencyFormatter.format(poSumAmount.pogrosssum)}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="ps-4">Deduction (Discount)</td>
                                                <td className="text-end">( {currencyFormatter.format(poMainData.discount)} )</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="ps-4">Addition (Transport)</td>
                                                <td className="text-end">{currencyFormatter.format(poMainData.transport)}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan="2" >Total Payable</th>
                                                <th className="text-end">{currencyFormatter.format(nettotal)}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">SVAT</td>
                                                <td className="text-end">{currencyFormatter.format(poMainData.vat)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card-footer">
                                <div className="row mt-4" style={{ fontSize: '12px' }}>
                                    <div className="col text-center">
                                        <dl>
                                            <dt>Prepared by:</dt>
                                            <br />
                                            <p>............................................<br />(Stores)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Verified by:</dt>
                                            <br />
                                            <p>............................................<br />(Accountant)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Authorized by:</dt>
                                            <br />
                                            <p>............................................<br />(Director Finance)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Approved by:</dt>
                                            <br />
                                            <p>............................................<br />(Managing Director)</p>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary mt-3' type="button" onClick={handlePrint}>Save or Print</button>
            </div>
        </div>
    )
}
