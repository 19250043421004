import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const initialState = {

    supplier_name: "", supplier_address: "", att_name: "", att_contact_no: "", acc: "", svat: "", quotation_ref_no: "", supplier_ref_type: "", supplier_ref: "", supplier_refdate: "",
    delivery_address: "", delivery_date: "", discount: "", transport: "", nbt: "", vat: "",
    id: "", item_name: "", qbrand: "", unit: "", quntity: "", qunit_price: "", total: ""

};

export default function Editpo() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "admin" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const { po_ref_no } = useParams();

    const [state, setState] = useState(initialState);

    const {
        supplier_name, supplier_address, att_name, att_contact_no, acc, svat, quotation_ref_no, supplier_ref, supplier_ref_type, supplier_refdate,
        delivery_address, delivery_date, discount, transport, nbt, vat,
        id, item_name, qbrand, unit, quntity, unit_price } = state;

    const total = quntity * unit_price

    //Get PO Main Data

    const [poMainData, setPOMainData] = useState([]);
    //console.log(poMainData)

    const lodePOMainData = async () => {
        const response = await axios.get(API_URL + `/getPOSuppliers/${po_ref_no}`);
        setPOMainData(response.data[0]);
    }

    //Get PO Details Data

    const [poDetailsData, setPODetailsData] = useState([]);

    const lodePODetailsData = async () => {
        const response = await axios.get(API_URL + `/getPODetails/${po_ref_no}`);
        setPODetailsData(response.data);

    }

    useEffect(() => {
        lodePOMainData();
        lodePODetailsData();
        lodePOSumAmount();
    }, []);

    const [showMain, setShowMain] = useState(false);
    const [showDetials, setShowDetials] = useState(false);

    //edit po main
    const handleMEdit = async (id) => {
        await axios.get(API_URL + `/getPOSuppliers/${id}`)
            .then((resp) => setState({ ...resp.data[0] }));
        setShowMain(true);
        setShowDetials(false);
    }

    //edit po detials
    const handleDEdit = async (id) => {
        await axios.get(API_URL + `/getEditPODetails/${id}`)
            .then((resp) => setState({ ...resp.data[0] }));
        setShowDetials(true);
        setShowMain(false);
    }

    //Get PO Amount sum

    const [poSumAmount, setPOSumAmount] = useState([]);
    const lodePOSumAmount = async () => {
        const response = await axios.get(API_URL + `/getposumamount/${po_ref_no}`);
        setPOSumAmount(response.data[0]);
    }

    let deliveryaddress = poMainData.delivery_address || "-,-,-,-,-"
    let da = deliveryaddress.split(',');
    let nettotal = (poSumAmount.pogrosssum + (poMainData.vat + poMainData.nbt + poMainData.transport) - (poMainData.discount))

    const handleSuppliersSubmit = (e) => {
        e.preventDefault();
        axios.patch(API_URL + `/updatePOMain/${po_ref_no}`, {
            supplier_name, supplier_address, att_name, att_contact_no, acc, svat, quotation_ref_no, supplier_ref, supplier_ref_type, supplier_refdate,
            delivery_address, delivery_date, discount, transport, nbt, vat
        })
            .then(() => {
                setState({
                    supplier_name: "", supplier_address: "", att_name: "", att_contact_no: "", acc: "", svat: "", quotation_ref_no: "", supplier_ref: "", supplier_ref_type: "", supplier_refdate: "",
                    delivery_address: "", delivery_date: "", discount: "", transport: "", nbt: "", vat: ""
                });
                lodePOMainData();
                toast.success("Success!");
            })
            .catch((err) => toast.error(err.response.data));
    }

    const handleDetailsSubmit = (e) => {
        e.preventDefault();
        if (!id || !item_name || !qbrand || !unit || !quntity || !unit_price || !total) {
            toast.error('Please fill in all data! (If there is no data to enter in the respective field, please enter the "-" mark or "0".)');
        } else {
            axios.patch(API_URL + `/updatePODetails/${id}`, {
                item_name, qbrand, unit, quntity, unit_price, total
            })
                .then(() => {
                    setState({
                        item_name: "", qbrand: "", unit: "", quntity: "", qunit_price: "", total: ""
                    });
                    toast.success("Success!");
                    lodePODetailsData();
                    lodePOSumAmount();
                })
                .catch((err) => toast.error(err.response.data));
        }

    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Edit Purchase Order</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <div className="card p-5" style={{ fontSize: '12px' }}>
                            <div className="card-header">
                                <div style={{ textAlign: "center" }}>
                                    <h4 style={{ marginBottom: '0' }}>Associated Speciality Rubbers (PVT) Ltd.</h4>
                                    <h5 style={{ marginTop: '0' }}>Purchase Order</h5>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <span>P/O Reference No : {po_ref_no}</span>
                                    </div>
                                    <div className="col-md-6 text-end">
                                        <span>Date : {moment(poMainData.date).format("YYYY/MM/DD")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="text-end">
                                {userData.account_type === "admin" || userData.account_type === "user" ? <button type="button" className='btnNoBackground'
                                    onClick={() => handleMEdit(po_ref_no)}><icons.FaEdit /></button> : null}
                            </div>
                            <div className="row mt-3">
                                {/* Supplier Information */}
                                <div className="col-md-7">
                                    <table className="table table-sm table-borderless table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-25">To</th>
                                                <td>
                                                    <strong>{poMainData.supplier_name}</strong><br />
                                                    {poMainData.supplier_address}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Att.</th>
                                                <td>
                                                    {poMainData.att_name}<br />
                                                    {poMainData.att_contact_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Acc. No.</th>
                                                <td>{poMainData.acc}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                                {/* Purchase Order Summary */}
                                <div className="col-md-5">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-40">S VAT</th>
                                                <td>{poMainData.svat}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Pay Term</th>
                                                <td>30 days</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Pay Mode</th>
                                                <td>Fund Transfer</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Bus. Reg.</th>
                                                <td>PV3704</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Q Ref. No.</th>
                                                <td>{poMainData.quotation_ref_no}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Delivery Details */}
                                <div className="col-md-7">
                                    <table className="table table-sm table-borderless">
                                        <tbody>
                                            <tr>
                                                <th className="w-25">Delivery Address</th>
                                                <td>{da[0]},<br />{da[1]},<br />{da[2]}, {da[3]}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Contact</th>
                                                <td>0774775615</td>
                                            </tr>
                                            <tr>
                                                <th className="w-25">Delivery Date</th>
                                                <td>{moment(poMainData.delivery_date).format("YYYY/MM/DD")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                {/* Supplier Reference Details */}
                                <div className="col-md-5">
                                    <table className="table table-sm table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th className="w-40">Sup. Ref. Type</th>
                                                <td>{poMainData.supplier_ref_type}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Sup. Ref No.</th>
                                                <td>{poMainData.supplier_ref}</td>
                                            </tr>
                                            <tr>
                                                <th className="w-40">Sup. Ref. Date</th>
                                                <td>{moment(poMainData.supplier_refdate).format("YYYY/MM/DD")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Purchase Order Items */}
                                <div className="col-12">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr className="table-primary text-center">
                                                <th className="w-10">No</th>
                                                <th>Item Name</th>
                                                <th className="w-10">Unit</th>
                                                <th className="w-10">Quantity</th>
                                                <th className="w-10">Unit Price</th>
                                                <th className="w-10">Total</th>
                                                <th className="w-10">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {poDetailsData.map((poDetails, index) => (
                                                <tr key={index}>
                                                    <th>{index + 1}</th>
                                                    <td>{poDetails.item_name}</td>
                                                    <td>{poDetails.unit}</td>
                                                    <td className="text-center">{poDetails.quntity}</td>
                                                    <td className="text-end">{currencyFormatter.format(poDetails.unit_price)}</td>
                                                    <td className="text-end">{currencyFormatter.format(poDetails.total)}</td>
                                                    <td className="text-center">
                                                        {userData.account_type === "admin" || userData.account_type === "user" ? <button type="button" className='btnNoBackground'
                                                            onClick={() => handleDEdit(poDetails.id)}><icons.FaEdit /></button> : null}
                                                    </td>
                                                </tr>
                                            ))}

                                            {/* Purchase Order Summary */}
                                            <tr>
                                                <td rowSpan="6" colSpan="3">

                                                </td>
                                                <th colSpan="2">Gross Total</th>
                                                <th className="text-end">{currencyFormatter.format(poSumAmount.pogrosssum)}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="ps-4">Deduction (Discount)</td>
                                                <td className="text-end">( {currencyFormatter.format(poMainData.discount)} )</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="ps-4">Addition (Transport)</td>
                                                <td className="text-end">{currencyFormatter.format(poMainData.transport)}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan="2" >Total Payable</th>
                                                <th className="text-end">{currencyFormatter.format(nettotal)}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">SVAT</td>
                                                <td className="text-end">{currencyFormatter.format(poMainData.vat)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="card-footer">
                                <div className="row mt-4" style={{ fontSize: '12px' }}>
                                    <div className="col text-center">
                                        <dl>
                                            <dt>Prepared by:</dt>
                                            <br />
                                            <p>............................................<br />(Stores)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Verified by:</dt>
                                            <br />
                                            <p>............................................<br />(Accountant)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Authorized by:</dt>
                                            <br />
                                            <p>............................................<br />(Director Finance)</p>
                                        </dl>
                                    </div>

                                    <div className="col text-center">
                                        <dl>
                                            <dt>Approved by:</dt>
                                            <br />
                                            <p>............................................<br />(Managing Director)</p>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showMain ? <form>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12 mt-2'>
                            <div>
                                <h6 className="mt-2">Supplier Name:</h6>
                                <input type='text' placeholder='Enter Supplier Name' className="form-control form-control-sm" name='supplier_name' onKeyDown={handleEnter} value={supplier_name || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Supplier Address:</h6>
                                <input type='text' placeholder='Enter Supplier Address' className="form-control form-control-sm" form-control-sm name='supplier_address' onKeyDown={handleEnter} value={supplier_address || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h6 className="mt-2">Att. Name:</h6>
                                <input type='text' placeholder='Enter Att' className="form-control form-control-sm" name='att_name' onKeyDown={handleEnter} value={att_name || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Att. Contact No.:</h6>
                                <input type='text' placeholder='Enter Att' className="form-control form-control-sm" name='att_contact_no' onKeyDown={handleEnter} value={att_contact_no || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Acc. No.:</h6>
                                <input type='text' placeholder='Enter Att' className="form-control form-control-sm" name='acc' onKeyDown={handleEnter} value={acc || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">S VAT:</h6>
                                <input type='text' placeholder='Enter S VAT' className="form-control form-control-sm" name='svat' onKeyDown={handleEnter} value={svat || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Quotation Rreference No:</h6>
                                <input type='text' placeholder='Enter Quotation Rreference No' className="form-control form-control-sm" name='quotation_ref_no' onKeyDown={handleEnter} value={quotation_ref_no || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Supplier Rreference Type:</h6>
                                <input type='text' placeholder='Enter Supplier Rreference Type' className="form-control form-control-sm" name='supplier_ref_type' onKeyDown={handleEnter} value={supplier_ref_type || ""} onChange={handleInputChange}></input>
                            </div>

                        </div>
                        <div className='col-md-6 col-sm-12 mt-2'>
                            <div>
                                <h6 className="mt-2">Supplier Rreference:</h6>
                                <input type='text' placeholder='Enter Supplier Rreference' className="form-control form-control-sm" name='supplier_ref' onKeyDown={handleEnter} value={supplier_ref || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Supplier Rreference Date:</h6>
                                <input type='date' placeholder='Enter Supplier Rreference Date' className="form-control form-control-sm" name='supplier_refdate' onKeyDown={handleEnter} value={supplier_refdate || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Delivery Address:</h6>
                                <select name="delivery_address" className="form-control form-control-sm" value={delivery_address} onChange={handleInputChange}>
                                    <option value="">Select Ordering  Status...</option>
                                    <option value="Associated Speciality Rubbers (Pvt) Ltd, Yatideriya Rubber Factory, Undugoda - 71200, Sri Lanka.">ASR</option>
                                    <option value="Associated Advanceed Rubbers (Pvt) Ltd, Diurumpitiya Rubber Factory, Getahette - 70620, Avissawella Sri Lanka.">AAR</option>
                                    <option value="Prestige Rubbers (Pvt) Ltd, 133/48, Mahena Rood, Siyambalape South Sri Lanka.">PR</option>
                                </select>
                            </div>

                            <div>
                                <h6 className="mt-2">Delivery Date:</h6>
                                <input type='date' placeholder='Enter Delivery Date' className="form-control form-control-sm" name='delivery_date' onKeyDown={handleEnter} value={delivery_date || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Discount:</h6>
                                <input type='text' placeholder='Enter Discount' className="form-control form-control-sm" name='discount' onKeyDown={handleEnter} value={discount || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Transport:</h6>
                                <input type='text' placeholder='Enter Transport' className="form-control form-control-sm" name='transport' onKeyDown={handleEnter} value={transport || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">NBT:</h6>
                                <input type='text' placeholder='Enter NBT' className="form-control form-control-sm" name='nbt' onKeyDown={handleEnter} value={nbt || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">VAT:</h6>
                                <input type='text' placeholder='Enter VAT' className="form-control form-control-sm" name='vat' onKeyDown={handleEnter} value={vat || ""} onChange={handleInputChange}></input>
                            </div>
                            <div className="text-end">
                                <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleSuppliersSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
                </form> : ""}

                {showDetials ? <form >
                    <div className='row'>
                        <div className='col-md-6 col-sm-12 mt-2'>
                            <div>
                                <h6 className="mt-2">Item Name:</h6>
                                <input type='text' placeholder='Enter Item Name' className="form-control form-control-sm" name='item_name' onKeyDown={handleEnter} value={item_name || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Brand:</h6>
                                <input type='text' placeholder='Enter Brand' className="form-control form-control-sm" name='qbrand' onKeyDown={handleEnter} value={qbrand || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit:</h6>
                                <input type='text' placeholder='Enter Unit' className="form-control form-control-sm" name='unit' onKeyDown={handleEnter} value={unit || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12 mt-2'>
                            <div>
                                <h6 className="mt-2">Qunatity:</h6>
                                <input type='text' placeholder='Enter Item Quantity' className="form-control form-control-sm" name='quntity' onKeyDown={handleEnter} value={quntity || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit Price:</h6>
                                <input type='text' placeholder='Enter Unit Price' className="form-control form-control-sm" name='unit_price' onKeyDown={handleEnter} value={unit_price || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Total:</h6>
                                <input type='text' readOnly placeholder='Check Total' className="form-control form-control-sm" name='total' onKeyDown={handleEnter} value={total || ""} onChange={handleInputChange}></input>
                            </div>

                            <div className="text-end">
                                <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleDetailsSubmit}>Update</button>
                            </div>
                        </div>
                    </div>
                </form> : ""}

            </div>
            <ToastContainer />
        </div>
    )
}
