import React, { useEffect } from 'react';
import './Pages.css';
import API_URL from '../config/config';
import NavBar from '../components/NavBar';
import * as icons from 'react-icons/fa';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


export default function Transaction() {

  const navigate = useNavigate();
  const [cookies] = useCookies([]);

  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/");
      }
    };
    verifyUser();
  });

  return (
    <div className='background'>
      <div className='contentbox p-1'>
        <div className='row'>
          <div className='col-3'>
            <NavBar />
          </div>


          <div className=' col-9'>
            <div>
              <h2>Inventory Management</h2>
            </div>

            <div className='row'>
              <div className='mt-2'>

                <div className='fleft mx-3 mt-2 ' >
                  <a href='/addtransaction' className='btn btn-outline-success' role='button'><icons.FaPlusCircle />  Add</a>
                </div>

                <div className='fleft mx-3 mt-2'>
                  <a href='/issuetransaction' className='btn btn-outline-success' role='button'><icons.FaMinusCircle /> Issue</a>
                </div>

                <div className='fleft mx-3 mt-2'>
                  <a href='/adjustptransactions' className='btn btn-outline-success' role='button'><icons.FaAdjust /> Adjust +</a>
                </div>

                <div className='fleft mx-3 mt-2'>
                  <a href='/adjustmtransactions' className='btn btn-outline-success' role='button'><icons.FaAdjust />  Adjust -</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
