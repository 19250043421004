import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import ReactExport from 'react-data-export';

const initialState={
    transactions_number:"",
    startdate:"",
    enddate:"",
    item_code:"",
    filename:""
    
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Addtransactions() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);
            
      useEffect(() => {
        const verifyUser = async () => {
          if (!cookies.jwt) {
            navigate("/");
          } 
        };
        verifyUser();
      });
    
    
    const [userData, setUserData]=useState([]);
    useEffect(()=>{
      
      const lodeUserData=async()=>{
          await axios.post(API_URL + "/profile",{},
            {
              withCredentials: true,
            })
            .then((resp)=>setUserData({...resp.data[0]}))
        }
    
        lodeUserData();
    },[]);


    const [addData, setAddData]=useState([]);

    const lodeAddData=async()=>{
        const response=await axios.get(API_URL + "/allAddTransactions");
        setAddData(response.data);
    }

    useEffect(()=>{
        lodeAddData();
    },[]);


    const[state, setState]=useState(initialState);
    const{transactions_number, startdate, enddate, item_code, filename}=state;

    const handleTransNo=async(e)=>{
        e.preventDefault();
        const response=await axios.get(API_URL + `/searchAddTransNo/${transactions_number}`)
        setAddData(response.data);
    }

    const handleDate=async(e)=>{
        e.preventDefault();
        const response=await axios.get(API_URL + `/searchAddicandDate/${item_code}/${startdate}/${enddate}`)
        setAddData(response.data);
    }



    const handleReset=(e)=>{
        
            lodeAddData()
            .then(()=>{
                setState({
                    transactions_number:"",
                    startdate:"",
                    enddate:"",
                    item_code:"",
                    filename:""
                });
            });
      };
 
      const DataSet = [
        {
            columns: [
                {title: "Transaction Number", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Date", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Item Code", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}},
                {title: "Item Name", style: {font: {sz: "12", bold: true}}, width: {wpx: 300}},
                {title: "Quntity", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Unit Price", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Amount", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}},
                {title: "Type", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}},
                {title: "Description", style: {font: {sz: "12", bold: true}}, width: {wpx: 120}}
                
            ],
    
            data: addData.map((transactions) => [
                                               
                {value: transactions.transactions_number, style:{font: {sz: "12"}}},
                {value: moment(transactions.date_time).format("YYYY/MM/DD"), style: {font: {sz: "12"}}},
                {value: transactions.item_code, style: {font: {sz: "12"}}},
                {value: transactions.item_name, style: {font: {sz: "12"}}},
                {value: transactions.quntity, style: {font: {sz: "12"}}},
                {value: transactions.unit_price, style:{font: {sz: "12"}}},
                {value: transactions.amount, style:{font: {sz: "12"}}},
                {value: transactions.type, style:{font: {sz: "12"}}},
                {value: transactions.discription, style:{font: {sz: "12"}}}
                ])
        }
    ]

    const handleInputChange=(e)=>{
        const{name,value} = e.target;
        setState({...state,[name]:value})
      };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-4'>

            <div className='row'>
                        <div className=' col-12'>
                            <div className='fleft mx-3'>  
                                <a href="/transaction">
                                    <icons.FaArrowCircleLeft size='2.5em' color='#274F6A'/>
                                </a>
                            </div>
                        
                            <div className='fleft'>
                                <h2>Add</h2>
                            </div>
                        </div>
                    
                    </div>
                <div className='row'>
                    <div className=' col-12'>
                        <div className='row'>
                        <div>
                            <div>  
                                <div className='fleft mx-3 mt-2'>
                                    <div className='input-group'>
                                        <div className="form-outline">
                                            <input type="search" className="form-control" placeholder='Transaction No...' name='transactions_number' value={transactions_number} onChange={handleInputChange}></input>
                                            
                                        </div>
                                        <button type="button" className="btn btn-success" onClick={handleTransNo}><icons.FaSearch/></button>
                                    </div>
                                </div>



                                <div className='fleft mx-3 mt-2'>
                                    <div className='input-group'>
                                    <input type="search" className="form-control" placeholder='Item Code...' name='item_code' value={item_code} onChange={handleInputChange}></input>
                                        <div className="form-outline">
                                        <input type="date" className="form-control" name='startdate' value={startdate} onChange={handleInputChange}></input>
                                        </div>
                                        <div className="form-outline">
                                        <input type="date" className="form-control" name='enddate' value={enddate} onChange={handleInputChange}></input>  
                                        </div>
                                        <button type="button" className="btn btn-success" onClick={handleDate}><icons.FaFilter/></button>
                                    </div>
                                </div>

                                <div className='fleft mx-3 mt-2'>
                                    <button type='button' className='btn btn-success ' onClick={handleReset}>Reset</button>
                                </div>

                                <div className='input-group'>
                                    <div className='mt-2 mx-3'>
                                        <div className="form-outline fleft ">
                                            <input type='text' className="form-control" placeholder='Enter File Name' name='filename' value={filename} onChange={handleInputChange}></input>
                                        </div>
                                        <ExcelFile 
                                            filename={filename || "Transactions"}
                                            element={<button type="button" className="btn btn-success">Export Data</button>}>
                                            <ExcelSheet dataSet={DataSet} name="Items List"/>
                                        </ExcelFile>
                                    </div>
                                </div>
                            </div> 
                    

                            <div className='mt-3'>
                                <div className='table-responsive me-3'>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Transaction<br/>Number</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Item<br/>Code</th>
                                                <th scope="col">Item<br/>Name</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Unit<br/>Price</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">User<br/>ID</th>
                                                {userData.account_type==="admin" ? <th scope="col">Action</th>:null}
                                                
                                            </tr>
                                        </thead>
                                    
                                        <tbody>
                                        {addData.map((transactions,index)=>(
                                                <tr key={index}>
                                                        <td>AD{transactions.transactions_number}</td>                                        
                                                        <td>{moment(transactions.date_time).format("YYYY/MM/DD")}</td>
                                                        <td>{transactions.item_code}</td>
                                                        <td>{transactions.item_name}</td>
                                                        <td style={{ textAlign: "right" }}>{transactions.quntity}</td>
                                                        <td style={{ textAlign: "right" }}>{transactions.unit_price}</td>
                                                        <td style={{ textAlign: "right" }}>{transactions.amount}</td>
                                                        <td>{transactions.type}</td>
                                                        <td>{transactions.discription}</td>
                                                        <td>{transactions.user_id}</td>
                                                        {userData.account_type==="admin" ?
                                                        <td className='d-flex'>
                                                            <Link to={`/editaddtransaction/${transactions.transactions_number}`}>
                                                            <icons.FaEdit/>
                                                            </Link>
                                                            
                                                        </td>
                                                        :null}
                                                
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
