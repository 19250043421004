import React, { useState, useEffect } from 'react';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const initialState={
  oldpassword:"",
  newpassword:"",
  confirmpassword:""
};

export default function Profile (){

  const navigate = useNavigate();
  const [cookies] = useCookies([]);
        
  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/");
      } 
    };
    verifyUser();
  });

  const [userData, setUserData]=useState([]);
  const lodeUserData=async()=>{
      await axios.post(API_URL + "/profile",{},
        {
          withCredentials: true,
        })
        .then((resp)=>setUserData({...resp.data[0]}))
    }

  useEffect(()=>{
      lodeUserData();
  },[]);

    const[state, setState]=useState(initialState);

    const{oldpassword, newpassword, confirmpassword }=state;
    const username=userData.username;

    const handleSubmit=(e)=>{
        e.preventDefault();
        if (!oldpassword || !newpassword || !confirmpassword ) {
            toast.error("Please fill in all data!");

        }else{
          if(newpassword===confirmpassword){
            axios.post(API_URL + "/changepassword",{
              username,
              oldpassword,
              newpassword

            })
            .then(()=>{
                setState({
                oldpassword:"",
                newpassword:"",
                confirmpassword:""
            });

                toast.success("Success!");
            })

            .catch((err)=>toast.error(err.response.data));
          }else{
            toast.error("The password confirmation doesn't match!");
        
          }
        }
    }

    const handleEnter = (event) => {
      if (event.key.toLowerCase() === "enter") {
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }}

  
  const handleInputChange=(e)=>{
      const{name,value} = e.target;
      setState({...state,[name]:value})
    };



    return (
      <div className='background'>
        <div className='container-fluid contentbox p-5'>


        <div className='row'>
          <div className='col-12 mt-2'>
            <div className='fleft'>  
              <a href="/dashboard">
                  <icons.FaArrowCircleLeft size='2.5em' color='#274F6A'/>
              </a>
            </div>
            
            <div className='fleft ms-2'>
              <h2>Profile</h2>
            </div>
          </div>
        </div>
          <div className='row mt-4'> 
            <div className='col-12 col-sm-6'>
            <h5>User ID:</h5><label>{userData.user_id}</label>
            <h5>Name:</h5><label>{userData.name}</label>
            <h5>Position:</h5><label>{userData.position}</label>
            <h5>Contact Number:</h5><label>{userData.contact_no}</label>
            <h5>Email:</h5><label>{userData.email}</label>
            <h5>Accopunt Type:</h5><label>{userData.account_type}</label>

              <div className='mt-4'>
                <h5>Change Password:</h5>
              
                <form onSubmit={handleSubmit}>
                        
                          <input type='password' name='oldpassword' placeholder='Enter Old Password' className="form-control mt-2" onKeyDown={handleEnter} 
                              value={oldpassword} onChange={handleInputChange}>
                          </input>
                    
                        
                          <input type='password' name='newpassword' placeholder='Enter New Password' className="form-control mt-2" onKeyDown={handleEnter} 
                              value={newpassword} onChange={handleInputChange}>
                          </input>
                  
                  
                        
                          <input type='password' name='confirmpassword' placeholder='Enter Confirm Password' className="form-control mt-2" onKeyDown={handleEnter} 
                              value={confirmpassword} onChange={handleInputChange}>
                          </input>
                    
                    <button type='submit' className='btn btn-success mt-2'>Change</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    )
  }
