import React, { useEffect } from 'react';
import './Pages.css';
import API_URL from '../config/config';
import NavBar from '../components/NavBar';
import * as icons from 'react-icons/fa';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function Purchasingmanagement() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    return (
        <div className='background'>
            <div className='contentbox p-1'>
                <div className='row'>
                    <div className='col-3'>
                        <NavBar />
                    </div>


                    <div className=' col-9'>
                        <div className='row mt-2'>
                            <div className='col-9'>
                                <h2> Purchasing Management</h2>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='mt-2'>

                                <div className='fleft mx-3 mt-2'><a href='/purchases' className='btn btn-outline-success' role='button'><icons.FaShippingFast /> Purchases </a></div>
                                <div className='fleft mx-3 mt-2'><a href='/quotationsall' className='btn btn-outline-success' role='button'><icons.FaRegNewspaper /> Quotations </a></div>
                                <div className='fleft mx-3 mt-2'><a href='/poall' className='btn btn-outline-success' role='button'><icons.FaFileAlt /> Purchase Order </a></div>
                                <div className='fleft mx-3 mt-2'><a href='/suppliers' className='btn btn-outline-success' role='button'><icons.FaUsers /> Suppliers </a></div>
                                <div className='fleft mx-3 mt-2'><a href='/unitprice' className='btn btn-outline-success' role='button'><icons.FaMoneyCheckAlt /> Unit Price </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
