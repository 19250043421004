import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


const initialState = {

    item_code: "",
    item_name: "",
    quntity: "",
    unit_price: "",
    amount: "",
    supplier_id: "",
    brand: "",
    quotation_ref_no: "",
    user_id: ""

};

export default function Editpurchases() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const [state, setState] = useState(initialState);

    const { purchase_id } = useParams();
    const { item_code, quntity, unit_price, supplier_id, brand, quotation_ref_no } = state;

    useEffect(() => {
        axios.get(API_URL + `/onePurchase/${purchase_id}`)
            .then((resp) => setState({ ...resp.data[0] }))
    }, [purchase_id])


    const [itemNameData, setItemNameData] = useState([]);

    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const response = await axios.get(API_URL + `/getItemName/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemNameData(response.data[0]);
        }
    }

    let amount = quntity * unit_price;
    let item_name = itemNameData.item_name;
    let user_id = userData.user_id;
    let user_fname = userData.name;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name || !quntity || !unit_price || !supplier_id || !brand || !quotation_ref_no) {
            toast.error("Please fill in all data!");
        } else {
            if (
                window.confirm("Are you sure?")
            ) {
                axios.patch(API_URL + `/updatePurchase/${purchase_id}`, {
                    item_code,
                    item_name,
                    quntity,
                    unit_price,
                    amount,
                    supplier_id,
                    brand,
                    quotation_ref_no,
                    user_fname,
                    user_id

                })
                    .then(() => {

                        toast.success("Success!");
                    })

                    .catch((err) => toast.error(err.response.data));
            }
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <a href="/purchases">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Edit Purchases</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>

                            <div>
                                <h5 className="mt-2">Item Code:</h5>
                                <input type='text' name='item_code' placeholder='Enter Item Code' className="form-control" value={item_code} onChange={handleInputChange}></input>
                            </div>
                            <button className='btn btn-secondary mt-2' onClick={handleCheckName}>Check Name</button>
                            <div>
                                <h5 className="mt-2">Item Name:</h5>
                                <input type='text' readOnly name='item_name' placeholder='Check Item Name' className="form-control" value={item_name} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Item Quantity:</h5>
                                <input type='text' placeholder='Enter Item Quantity' className="form-control" name='quntity' value={quntity} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Unit Price:</h5>
                                <input type='text' placeholder='Enter Unit Price' className="form-control" name='unit_price' value={unit_price} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Amount:</h5>
                                <input type='text' readOnly placeholder='Check Amount' className="form-control" name='amount' value={amount} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Supplier ID:</h5>
                                <input type='text' placeholder='Enter Supplier ID' className="form-control" name='supplier_id' value={supplier_id} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Brand:</h5>
                                <input type='text' placeholder='Enter Brand' className="form-control" name='brand' value={brand} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Quotation Ref. No.:</h5>
                                <input type='text' placeholder='Enter Quotation Ref. No.' className="form-control" name='quotation_ref_no' value={quotation_ref_no} onChange={handleInputChange}></input>
                            </div>


                            <button type='submit' className='btn btn-success mt-2' >Update</button>

                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
