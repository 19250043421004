import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';


const initialState = {
    name: "",
    address: "",
    contact_no: "",
    email: "",
    svat: "",
    account_no: "",
    account_name: "",
    account_bank: "",
    account_branch: "",
    account_branch_code: ""

};

export default function Editsuppliers() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }
    const [state, setState] = useState(initialState);

    const supplier_id = window.location.href.split('/')[4];


    useEffect(() => {
        axios.get(API_URL + `/oneSupplier/${supplier_id}`)
            .then((resp) => setState({ ...resp.data[0] }))
    }, [supplier_id])

    const { name, address, contact_no, email, svat, account_no, account_name, account_bank, account_branch, account_branch_code } = state;
    const user_id = userData.user_id;
    const user_fname = userData.name;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name) {
            toast.error("Supplier Name is required!");
        } else {
            axios.patch(API_URL + `/updateSupplier/${supplier_id}`, {
                name,
                address,
                contact_no,
                email,
                svat,
                account_no,
                account_name,
                account_bank,
                account_branch,
                account_branch_code,
                user_id,
                user_fname

            })
                .then(() => {
                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }

    }

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <a href="/suppliers">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Edit Suppliers </h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>

                            <div>
                                <h5 className="mt-2">Supplier Name:</h5>
                                <input type='text' name='name' placeholder='Enter Supplier Name' onKeyDown={handleEnter} className="form-control" value={name || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Address:</h5>
                                <input type='text' name='address' placeholder='Enter Supplier Address' className="form-control" onKeyDown={handleEnter} value={address || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Contact No:</h5>
                                <input type='text' placeholder='Enter Contact No' className="form-control" name='contact_no' onKeyDown={handleEnter} value={contact_no || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Email:</h5>
                                <input type='text' placeholder='Enter Email' className="form-control" name='email' onKeyDown={handleEnter} value={email || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">SVAT Registration:</h5>
                                <select name="svat" className="form-control" onKeyDown={handleEnter} value={svat || ""} onChange={handleInputChange}>
                                    <option value="">Select SVAT Registration...</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>

                            <div>
                                <h5 className="mt-2">Account No:</h5>
                                <input type='text' placeholder='Enter Account No' className="form-control" name='account_no' onKeyDown={handleEnter} value={account_no || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Account Name:</h5>
                                <input type='text' placeholder='Enter Account Name' className="form-control" name='account_name' onKeyDown={handleEnter} value={account_name || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Account Bank:</h5>
                                <input type='text' placeholder='Enter Account Bank' className="form-control" name='account_bank' onKeyDown={handleEnter} value={account_bank || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Account Branch:</h5>
                                <input type='text' placeholder='Enter Account Branch' className="form-control" name='account_branch' onKeyDown={handleEnter} value={account_branch || ""} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Account Branch Code:</h5>
                                <input type='text' placeholder='Enter Account Branch Code' className="form-control" name='account_branch_code' onKeyDown={handleEnter} value={account_branch_code || ""} onChange={handleInputChange}></input>
                            </div>

                            <button type='submit' className='btn btn-success mt-2' > Update</button>

                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
