import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const initialState = {
    item_code: "",
    item_name: "",
    unit: "",
    category: "",
    is_account: "",
    reorder_level: "",
    stock: ""
};

export default function Edititem() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "super_admin") {
        navigate("/");
    }


    const [categorydata, setCategoryData] = useState([]);

    const lodeCategoryData = async () => {
        const response = await axios.get(API_URL + "/selectcategorydata");
        setCategoryData(response.data);

    }

    const [unitdata, setUnitData] = useState([]);

    const lodeUnitData = async () => {
        const response = await axios.get(API_URL + "/selectunitdata");
        setUnitData(response.data);

    }

    useEffect(() => {
        lodeCategoryData();
        lodeUnitData();
    }, []);


    const [state, setState] = useState(initialState);

    const { id } = useParams();
    const { item_code, item_name, unit, category, is_account, reorder_level, stock } = state;
    const user_id = userData.user_id;
    const user_fname = userData.name;


    useEffect(() => {
        axios.get(API_URL + `/oneItem/${id}`)
            .then((resp) => setState({ ...resp.data[0] }))
    }, [id])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name || !unit || !category || !is_account) {
            toast.error("Please fill in all data!");

        } else {

            if (
                window.confirm("Are you sure?")
            ) {
                axios.patch(API_URL + `/updateItem/${id}`, {
                    item_code,
                    item_name,
                    unit,
                    category,
                    is_account,
                    reorder_level,
                    stock,
                    user_fname,
                    user_id

                })
                    .then(() => {
                        toast.success("Success!");
                    })
                    .catch((err) => toast.error(err.response.data));
            }
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
            <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Edit Item</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>
                            <div>
                                <h6 className="mt-2">Item Code:</h6>
                                <input type='text' readOnly name='item_code' placeholder='Enter Item Code' className="form-control form-control-sm" value={item_code || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Item Name:</h6>
                                <input type='text' name='item_name' placeholder='Check Item Name' className="form-control form-control-sm" value={item_name || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit:</h6>
                                <select name="unit" className="form-control form-control-sm" value={unit || ""} onChange={handleInputChange}>
                                    {
                                        unitdata.map((getunit, index) => (
                                            <option value={getunit.unit_name} key={index}>{getunit.unit_name}</option>
                                        ))
                                    }
                                </select>
                            </div>


                            <div>
                                <h6 className="mt-2">Category:</h6>
                                <select name='category' className="form-control form-control-sm" value={category || ""} onChange={handleInputChange}>
                                    {
                                        categorydata.map((getcategory, index) => (
                                            <option value={getcategory.category_name} key={index}>{getcategory.category_name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div>
                                <h6 className="mt-2">Use Accounts:</h6>
                                <select name='is_account' className="form-control form-control-sm" value={is_account} onChange={handleInputChange}>
                                    <option value="">Select Use for accounts...</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>

                            <div>
                                <h6 className="mt-2">Reorder Level:</h6>
                                <input type='text' name='reorder_level' placeholder='Enter Reorder Level' className="form-control form-control-sm" value={reorder_level} onChange={handleInputChange}></input>
                            </div>
                            {userData.username === "admin123" ?
                                <div>
                                    <h6 className="mt-2">Stock:</h6>
                                    <input type='text' name='stock' placeholder='Enter stock' className="form-control form-control-sm" value={stock || ""} onChange={handleInputChange}></input>
                                </div> : null}


                            <button type='submit' className='btn btn-sm btn-success mt-2' >Update</button>

                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
