import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

export default function Printquotations() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });


    const qrn = window.location.href.split('/')[4];


    //Get Q Main Data
    const [QMainData, setQMainData] = useState([]);

    const lodeQMainData = async () => {
        const response = await axios.get(API_URL + `/getQSuppliers/${qrn}`);
        setQMainData(response.data[0]);

    }

    useEffect(() => {
        lodeQMainData();
    }, []);

    let quotation_ref_no = QMainData.quotation_ref_no;

    //Get Q Details Data

    const [QDetailsData, setQDetailsData] = useState([]);

    const lodeQDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationDetails/${quotation_ref_no}`);
        setQDetailsData(response.data);

    }

    //Get Q LP Details Data

    const [QLPDetailsData, setQLPDetailsData] = useState([]);

    const lodeQLPDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationLPDetails/${quotation_ref_no}`);
        setQLPDetailsData(response.data);

    }

    //Get Q TBP Details Data

    const [QTBPDetailsData, setQTBPDetailsData] = useState([]);

    const lodeQTBPDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationTBPDetails/${quotation_ref_no}`);
        setQTBPDetailsData(response.data);

    }

    //Get Q Amount sum

    const [supAmount, setSupAmount] = useState([]);
    const lodeSupAmount = async () => {
        const response = await axios.get(API_URL + `/getsupamount/${quotation_ref_no}`);
        setSupAmount(response.data[0]);
    }


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `quotation ${quotation_ref_no}`,
    });

    const currencyformatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });


    useEffect(() => {
        lodeQDetailsData();
        lodeQLPDetailsData();
        lodeQTBPDetailsData();
        lodeSupAmount();
    }, [quotation_ref_no]);

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft ms-2'>
                            <h4>Save or Print Quotation</h4>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <div style={{ outline: "solid" }}>
                            <div ref={componentRef} className='p-5' >
                                <div>
                                    <div className="text-center"> <h5>Associated Speciality Rubbers (PVT) Ltd. <br /> Quotation for Purchase Order</h5></div>
                                    <hr style={{ color: 'black', height: '1px', }} />
                                    <div className="row">
                                        <div className="fleft">
                                            <h6 className="fleft col-sm-6">Quotation Reference No : ASR/{quotation_ref_no}</h6>
                                            <h6 className="fleft text-end col-sm-6">Date : {moment(QMainData.date).format("YYYY/MM/DD")}</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ color: 'black', height: '1px', }} />
                                <h5>Supplier quotations and details</h5>
                                <div className="row">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr className="align-top text-center">
                                                <th rowSpan="2" scope="col">No</th>
                                                <th rowSpan="2" scope="col">Item<br/>Code</th>
                                                <th rowSpan="2" scope="col">Item<br/>Name</th>
                                                <th rowSpan="2" scope="col">Brand</th>
                                                <th rowSpan="2" scope="col">Use<br/>For</th>
                                                <th rowSpan="2" scope="col">Request<br/>Person</th>
                                                <th rowSpan="2" scope="col">Extra<br/>Note</th>
                                                <th rowSpan="2" scope="col">Unit</th>
                                                <th rowSpan="2" scope="col">Existing<br/>Stock</th>
                                                <th colSpan="4" scope="col">Unit<br/>Price</th>
                                                <th rowSpan="2" scope="col">Quntity</th>
                                                <th colSpan="4" scope="col">Amount</th>
                                                <th rowSpan="2" scope="col" style={{ border: "none"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                            </tr>
                                            <tr className="text-center">
                                                <th scope="col">{QMainData.qsupplier01}</th>
                                                <th scope="col">{QMainData.qsupplier02}</th>
                                                <th scope="col">{QMainData.qsupplier03}</th>
                                                <th scope="col">{QMainData.qsupplier04}</th>
                                                <th scope="col">{QMainData.qsupplier01}</th>
                                                <th scope="col">{QMainData.qsupplier02}</th>
                                                <th scope="col">{QMainData.qsupplier03}</th>
                                                <th scope="col">{QMainData.qsupplier04}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {QDetailsData.map((qDetails, index) => (
                                                <tr key={index}>

                                                    <th scope='row'>{index + 1}</th>

                                                    <td>{qDetails.item_code}</td>
                                                    <td>{qDetails.item_name}</td>
                                                    <td>{qDetails.qbrand}</td>
                                                    <td>{qDetails.use_for}</td>
                                                    <td>{qDetails.reqest_person}</td>
                                                    <td>{qDetails.extra_note}</td>
                                                    <td>{qDetails.unit}</td>
                                                    <td className="text-end">{qDetails.stock}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price01)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price02)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price03)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price04)}</td>
                                                    <td className="text-end">{qDetails.quntity}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.amount01)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.amount02)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.amount03)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.amount04)}</td>

                                                </tr>
                                            ))}
                                            <tr>
                                                <th colSpan="14">Total</th>
                                                <th className="text-end">{currencyformatter.format(supAmount.sup01sum)}</th>
                                                <th className="text-end">{currencyformatter.format(supAmount.sup02sum)}</th>
                                                <th className="text-end">{currencyformatter.format(supAmount.sup03sum)}</th>
                                                <th className="text-end">{currencyformatter.format(supAmount.sup04sum)}</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <dt className="col-sm-3">Last Purchase Details:</dt>
                                <div className="row">
                                    <table className="table table-sm table-bordered table-hover">
                                        <thead>
                                            <tr className="text-center" >
                                                <th scope="col">No</th>
                                                <th scope="col">Item Code</th>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Purchases ID</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Supplier</th>
                                                <th scope="col">Brand</th>
                                                <th scope="col">Quntity</th>
                                                <th scope="col">Unit Price</th>
                                                <th scope="col">Amount</th>

                                            </tr>
                                        </thead>

                                        {QLPDetailsData.length > 0 ?
                                            <tbody>
                                                {QLPDetailsData.map((qLPDetails, index) => (
                                                    qLPDetails.lpquntity !== 0 ?
                                                    <tr key={index}>
                                                        <th scope='row'>{index + 1}</th>
                                                        <td>{qLPDetails.item_code}</td>
                                                        <td>{qLPDetails.item_name}</td>
                                                        <td>{qLPDetails.purchase_id}</td>
                                                        <td>{moment(qLPDetails.lpdate).format("YYYY/MM/DD")}</td>
                                                        <td>{qLPDetails.lpsupplier}</td>
                                                        <td>{qLPDetails.lpbrand}</td>
                                                        <td className="text-center">{qLPDetails.lpquntity}</td>
                                                        <td className="text-end">{currencyformatter.format(qLPDetails.lpunit_price)}</td>
                                                        <td className="text-end">{currencyformatter.format(qLPDetails.lpunit_price * qLPDetails.lpquntity)}</td>
                                                    </tr>: null
                                                ))}
                                            </tbody> : <tr><td>No Record Found</td></tr>}
                                    </table>
                                </div>

                                <div>
                                    <dl className="row">
                                        <dt className="col-lg-1 col-sm-2">Remarks:</dt>
                                        <dd className="col-sm-9">{QMainData.remarks}</dd>
                                    </dl>
                                </div>

                                <hr style={{ color: 'black', height: '1px', }} />
                                <h5>Quotation approval</h5>

                                <div className="row">
                                    <div className="fleft col-sm-6" >
                                        <dl className="row">
                                            <dt className="col-sm-4">Approval Type:</dt>
                                            <dd className="col-sm-2">{QMainData.response_type}</dd>
                                        </dl>
                                    </div>

                                    <div className="fleft col-sm-6" >
                                        {QMainData.response_type !== "pending" ?
                                            <dl className="row">
                                                <dt className="col-sm-4">Response Date:</dt>
                                                <dd className="col-sm-3">{moment(QMainData.response_date).format("YYYY/MM/DD")}</dd>
                                            </dl> : ""}
                                    </div>
                                </div>

                                {QMainData.response_type === "accept" ? <div>
                                    <dt className="col-sm-6">Details to purchase:</dt>
                                    <div className="row">
                                        <table className="table table-sm table-bordered table-hover">
                                            <thead>
                                                <tr className="text-center" >
                                                    <th scope="col">No</th>
                                                    <th scope="col">Item code</th>
                                                    <th scope="col">Item Name</th>
                                                    <th scope="col">Brand</th>
                                                    <th scope="col">Supplier to be purchased</th>
                                                    <th scope="col">Quantities to be purchased</th>
                                                    <th scope="col">Unit Price</th>
                                                    <th scope="col">Total</th>
                                                </tr>
                                            </thead>

                                            {QTBPDetailsData.length > 0 ?
                                                <tbody>
                                                    {QTBPDetailsData.map((qTBPDetails, index) => (
                                                        <tr key={index}>
                                                            <th scope='row'>{index + 1}</th>
                                                            <td>{qTBPDetails.item_code}</td>
                                                            <td>{qTBPDetails.item_name}</td>
                                                            <td>{qTBPDetails.qbrand}</td>
                                                            <td>{qTBPDetails.buy_supplier}</td>
                                                            <td className="text-center">{qTBPDetails.buy_quantity}</td>
                                                            <td className="text-end">{currencyformatter.format(qTBPDetails.buy_unitprice)}</td>
                                                            <td className="text-end">{currencyformatter.format(qTBPDetails.buy_amount)}</td>

                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <th colSpan="7">Total</th>
                                                        <th className="text-end">{currencyformatter.format(supAmount.buysum)}</th>
                                                    </tr>
                                                </tbody> : "No Record Found"}
                                        </table>
                                    </div>

                                </div> : ""}

                                <div>
                                    <dl className="row">
                                        <dt className="col-sm-2">Remarks:</dt>
                                        <dd className="col-sm-9">{QMainData.a_remarks}</dd>
                                    </dl>
                                </div>

                                <hr style={{ color: 'black', height: '1px', }} />

                                <div className="row">
                                <div >
                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Prepared by:</dt>
                                                <p>{QMainData.prepared_by}<br />{QMainData.prepared_position}<br />{moment(QMainData.date).format("YYYY/MM/DD")}</p>
                                            </dl>
                                        </div>
                                        {QMainData.for_verify !== "noneed" ?
                                            <div className="fleft col-sm-3">
                                                <dl>
                                                    <dt>Verified by:</dt>
                                                    {QMainData.verify_by !== null ?
                                                        <p>{QMainData.verify_by}<br />{QMainData.verify_position}<br />{moment(QMainData.verify_date).format("YYYY/MM/DD")}</p>: "............................."}
                                                </dl>
                                            </div> : ""}

                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Checked by:</dt>
                                                {QMainData.checked_by !== null ?
                                                <p>{QMainData.checked_by}<br />{QMainData.checked_position}<br />{moment(QMainData.checked_date).format("YYYY/MM/DD")}</p>: "............................."}
                                            </dl>
                                        </div>

                                        <div className="fleft col-sm-3">
                                            <dl>
                                                <dt>Approved by:</dt>
                                                {QMainData.responsed_by !== null ?
                                                <p>{QMainData.responsed_by}<br /> {QMainData.responsed_position} <br /> {moment(QMainData.response_date).format("YYYY/MM/DD")}</p>: "............................."}
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='btn btn-primary mt-3' type="button" onClick={handlePrint}>Save or Print</button>
                    </div>
                </div>

            </div>
        </div>
    )
}
