import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


const initialState = {
    date_time: "",
    item_code: "",
    item_name: "",
    quntity: "",
    unit_price: "",
    amount: "",
    discription: "-",
    user_id: ""
}
export default function Adjustminus() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }


    const [state, setState] = useState(initialState);

    const { date_time, item_code, quntity, unit_price, discription } = state;

    const [itemNameData, setItemNameData] = useState([]);

    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const response = await axios.get(API_URL + `/getItemName/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemNameData(response.data[0]);
        }
    }

    let user_id = userData.user_id;
    let amount = quntity * unit_price;
    let item_name = itemNameData.item_name;
    let user_fname = userData.name;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!date_time || !item_code || !item_name || !quntity || !unit_price || !amount || !discription) {
            toast.error("Please fill in all data!");
        } else {
            axios.post(API_URL + "/adjustMinusTransaction", {
                date_time,
                item_code,
                item_name,
                quntity,
                unit_price,
                amount,
                discription,
                user_id,
                user_fname

            })
                .then(() => {
                    setState({
                        date_time: "",
                        item_code: "",
                        item_name: "",
                        quntity: "",
                        unit_price: "",
                        amount: "",
                        discription: "",
                        user_id: ""
                    });

                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));


        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <a href="/dashboard">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Adjust -</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form>
                            <div>
                                <h5 className="mt-2">Date:</h5>
                                <input type="date" className="form-control" name='date_time' onKeyDown={handleEnter} value={date_time} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Item Code:</h5>
                                <input type='text' placeholder='Enter Item Code' className="form-control" name='item_code' onKeyDown={handleEnter} value={item_code} onChange={handleInputChange}></input>
                            </div>
                            <button className='btn btn-secondary mt-2' onKeyDown={handleEnter} onClick={handleCheckName}>Check Name</button>
                            <div>
                                <h5 className="mt-2">Item Name:</h5>
                                <input type='text' readOnly placeholder='Check Item Name' className="form-control" name='item_name' onKeyDown={handleEnter} value={item_name || ''} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Item Quantity:</h5>
                                <input type='text' placeholder='Enter Item Quantity' className="form-control" name='quntity' onKeyDown={handleEnter} value={quntity} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Unit Price:</h5>
                                <input type='text' placeholder='Enter Unit Price' className="form-control" name='unit_price' onKeyDown={handleEnter} value={unit_price} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h5 className="mt-2">Amount:</h5>
                                <input type='text' placeholder='Check Amount' className="form-control" name='amount' onKeyDown={handleEnter} value={amount} onChange={handleInputChange}></input>
                            </div>
                            <div>
                                <h5 className="mt-2">Discription:</h5>
                                <input type='text' placeholder='Enter Discription' className="form-control" name='discription' onKeyDown={handleEnter} value={discription} onChange={handleInputChange}></input>
                            </div>
                            <button type='submit' className='btn btn-success mt-2' onClick={handleSubmit}>Save</button>
                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}