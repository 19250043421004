import React, { useState, useEffect } from 'react';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactExport from 'react-data-export';
import { ToastContainer, toast } from 'react-toastify';

const initialState = {
    item_code: "",
    item_name: "",
    unit_price: "",
    sitem_code: "",
    sitem_name: "",
    filename: "",
    month: "",
    selectMonth: ""
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Unitprice() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();

    });

    const [data, setData] = useState([]);
    const lodeData = async () => {
        const response = await axios.get(API_URL + "/allUnitPrice");
        setData(response.data);
    }

    const [state, setState] = useState(initialState);
    const { sitem_code, sitem_name, item_code, unit_price, item_name, filename } = state;

    const handleItemCode = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchItemscode/${sitem_code}`)
        setData(response.data);
    }


    const handleitemname = async (e) => {
        e.preventDefault();
        const response = await axios.get(API_URL + `/searchitemname/${sitem_name}`)
        setData(response.data);
    }

    const handleReset = (e) => {

        lodeData()
            .then(() => {
                setState({
                    sitem_code: "",
                    sitem_name: "",
                    filename: ""
                });
            });
    };


    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const response = await axios.get(API_URL + `/getOneUnitPrice/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setState(response.data[0])

        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!item_code || !item_name || !unit_price) {
            toast.error("Please fill in all data!");
        } else {
            axios.patch(API_URL + `/updateUnitPrice/${item_code}`, {

                unit_price
            })
                .then(() => {
                    setState({

                        item_code: "",
                        item_name: "",
                        unit_price: ""
                    });

                    toast.success("Success!");
                })

                .catch((err) => toast.error(err.response.data));
        }
    }

    const DataSet = [
        {
            columns: [
                { title: "Item Code", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Item Name", style: { font: { sz: "12", bold: true } }, width: { wpx: 250 } },
                { title: "Unit Price", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
            ],

            data: data.map((unitprice) => [

                { value: unitprice.item_code, style: { font: { sz: "12" } } },
                { value: unitprice.item_name, style: { font: { sz: "12" } } },
                { value: unitprice.unit_price, style: { font: { sz: "12" } } },
            ])
        }
    ]


    useEffect(() => {
        lodeData();

    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-4'>

                <div className='row'>
                    <div className=' col-12'>
                        <div className='fleft mx-3'>
                            <a href="/purchasingmanagement">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft'>
                            <h2>Unit Price</h2>
                        </div>
                    </div>

                </div>

                <div className=' col-12'>

                    <div className='row'>
                        <div>

                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Item Code...' name='sitem_code' value={sitem_code || ""} onChange={handleInputChange}></input>

                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleItemCode}><icons.FaSearch /></button>
                                </div>
                            </div>

                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Item Name...' name='sitem_name' value={sitem_name || ""} onChange={handleInputChange}></input>

                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleitemname}><icons.FaSearch /></button>
                                </div>
                            </div>

                            <div className='fleft mt-2 mx-3 mt-2'>
                                <button type='button' className='btn btn-success ' onClick={handleReset}>Reset</button>
                            </div>

                            <div className='input-group'>
                                <div className='mt-2 mx-3'>
                                    <div className="form-outline fleft ">
                                        <input type='text' className="form-control" placeholder='Enter File Name' name='filename' value={filename || ""} onChange={handleInputChange}></input>
                                    </div>
                                    <ExcelFile
                                        filename={filename || "Unit Price"}
                                        element={<button type="button" className="btn btn-success">Export Data</button>}>
                                        <ExcelSheet dataSet={DataSet} name="Items List" />
                                    </ExcelFile>
                                </div>

                            </div>



                            <form>
                                <h5 className="mt-2 mx-3">Update Unit Price:</h5>
                                <div className='input-group fleft mt-2'>
                                    <div className='mx-3'>
                                        <div className="form-outline fleft ">
                                            <input type='text' className="form-control" placeholder='Enter Item Code' name='item_code' value={item_code || ""} onChange={handleInputChange}></input>
                                        </div>
                                        <div className="fleft">
                                            <button className='btn btn-secondary ' onClick={handleCheckName} ><icons.FaLongArrowAltRight /></button>
                                        </div>

                                    </div>

                                    <div className="form-outline fleft mx-3">
                                        <input type='text' readOnly className="form-control" placeholder='Item Name' name='item_name' value={item_name || ""} onChange={handleInputChange}></input>
                                    </div>

                                    <div className="form-outline fleft mx-3">
                                        <input type='text' className="form-control" placeholder='Enter Unit Price' name='unit_price' value={unit_price || ""} onChange={handleInputChange}></input>
                                    </div>

                                    <div className="fleft mx-3">
                                        <button type="button" className="btn btn-success " onClick={handleSubmit}>Update</button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                    <div className='mt-3 mx-3'>

                        <div className='table-responsive me-3'>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Item<br />Code</th>
                                        <th scope="col">Item<br />Name</th>
                                        <th scope="col">Unit<br />Price</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    {data.map((unitprice, index) => (
                                        <tr key={index}>
                                            <th scope='row'>{index + 1}</th>
                                            <td>{unitprice.item_code}</td>
                                            <td>{unitprice.item_name}</td>
                                            <td>{unitprice.unit_price}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
