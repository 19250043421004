import React, { useState, useEffect } from 'react';
import './NavBar.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from 'axios';


export default function NavBar() {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/");
      }
    };
    verifyUser();
  }
  );

  const [userData, setData] = useState([]);

  const lodeUserData = async () => {
    await axios.post(API_URL + "/profile", {},
      {
        withCredentials: true,
      })
      .then((resp) => setData({ ...resp.data[0] }))

  }

  function setNohowUser() {
    if (userData.account_type === "user") {
      return false;
    } else {
      return true;
    }
  }
  const noshowUser = setNohowUser();

  useEffect(() => {
    lodeUserData();
  }, []);

  let user_id = userData.user_id;
  let user_fname = userData.name;


  const logOut = () => {
    if (
      window.confirm("Are you sure?")
    ) {
      removeCookie("jwt", "", { maxAge: 1 });
      navigate("/");

      axios.post(API_URL + "/logoutlog", {
        user_id,
        user_fname

      })
    }
  };

  return (
    <div className='sidenav'>
      <div className='px-2 pt-3 pb-4'>

        <div className='text-center' >
          <div className='asrstorelogo mx-auto d-block'></div>
          <a href="/Profile">
            <icons.FaUserCircle size='7em' color='CCCCCC' className='mx-auto d-block mt-3' />
          </a>
          <label >{userData.user_id}</label> <br />
          <label >{userData.account_type}</label><br />
          <label >{userData.name}</label> <br />
          <label >{userData.position}</label>
        </div>
        <ul className='list-unstyled px-2'>
          <li className=''><a href='/dashboard' className='btn btn-sm btn-outline-success mt-2 d-block text-start' role='button'><icons.FaHome /> Dashboard</a></li>
          <li className=''><a href='/item' className='btn btn-sm btn-outline-success mt-2 d-block text-start' role='button'><icons.FaShoppingBasket /> Items</a></li>
          <li className=''><a href='/transaction' className='btn btn-sm btn-outline-success mt-2 d-block text-start' role='button'><icons.FaMoneyCheckAlt /> Inventory Management</a></li>
          <li className=''><a href='/purchasingmanagement' className='btn btn-sm btn-outline-success mt-2 d-block text-start' role='button'><icons.FaShoppingCart /> Purchasing Management</a></li>
          {noshowUser ?
            <li className=''><a href='/useraccount' className='btn btn-sm btn-outline-success mt-2 d-block text-start' role='button'><icons.FaUsers /> User Accounts</a></li>
            : null}
        </ul>

        <hr className='mx-2' style={{ color: 'black', height: '3px', }} />


        <div className='d-block mx-auto'>
          <ul className='list-unstyled px-2'>

            <li className='d-grid mx-auto'>
              <button className='btn btn-sm btn-outline-danger text-start' onClick={logOut}><icons.FaPowerOff /> Log Out</button></li>

          </ul>

        </div>
      </div>

    </div>

  )
}
