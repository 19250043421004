import React, { useState, useEffect } from 'react';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import NavBar from '../components/NavBar';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


export default function Dashboard() {

  const navigate = useNavigate();
  const [cookies] = useCookies([]);

  useEffect(() => {
    const verifyUser = async () => {
      if (!cookies.jwt) {
        navigate("/");
      }
    };
    verifyUser();
  });

  const [userData, setUserData] = useState([]);
  const lodeUserData = async () => {
    await axios.post(API_URL + "/profile", {},
      {
        withCredentials: true,
      })
      .then((resp) => setUserData({ ...resp.data[0] }))
  }

  useEffect(() => {
    lodeUserData();
  }, []);

  const [toVerifyQData, setToVerifyQData] = useState([]);
  const lodeToVerifyQData = async () => {
    const response = await axios.get(API_URL + "/getToVerifyQuotations");
    setToVerifyQData(response.data);
  }

  const [toCheckQData, setToCheckQData] = useState([]);
  const lodeToCheckQData = async () => {
    const response = await axios.get(API_URL + "/getToCheckQuotations");
    setToCheckQData(response.data);
  }

  const [toApprovalQData, setToApprovalQData] = useState([]);
  const lodeToApprovalQData = async () => {
    const response = await axios.get(API_URL + "/getToApprovalQuotations");
    setToApprovalQData(response.data);
  }


  const [toOrderQData, setToOrderQData] = useState([]);
  const lodeToOrderQData = async () => {
    const response = await axios.get(API_URL + "/getToOrderQuotations");
    setToOrderQData(response.data);
  }


  useEffect(() => {
    lodeToVerifyQData();
    lodeToCheckQData();
    lodeToApprovalQData();
    lodeToOrderQData();

  }, []);

  return (
    <div className='background'>
      <div className='contentbox p-1'>
        <div className='row'>
          <div className='col-sm-3'>
            <NavBar />
          </div>

          <div className='col-9'>
            <div className='row mt-2'>
              <div className='col-sm-9'>
                <h2>Dashboard</h2>
              </div>
            </div>

            <div className='row mt-3'>
            <hr style={{color: 'black', height: '1px'}}/>
            <h5>Quotation Status</h5>

              <div className="card col-sm-2 me-2 text-center">
                <div className="card-header">
                  <h5 className="card-title">To Verify</h5>
                </div>
                <div className="card-body">

                  <h2 className="card-text">{toVerifyQData.length}</h2>
                  <a href="/quotationstoverify" className="btn btn-primary">View</a>
                </div>

              </div>

              <div className="card col-sm-2 me-2 text-center">
                <div className="card-header">
                  <h5 className="card-title">To Check</h5>
                </div>
                <div className="card-body">

                  <h2 className="card-text">{toCheckQData.length}</h2>
                  <a href="/quotationstocheck" className="btn btn-primary">View</a>
                </div>

              </div>

              <div className="card col-sm-2 me-2 text-center">
                <div className="card-header">
                  <h5 className="card-title">To Approval</h5>
                </div>
                <div className="card-body">

                  <h2 className="card-text">{toApprovalQData.length}</h2>
                  <a href="/quotationstoapproval" className="btn btn-primary">View</a>
                </div>

              </div>

              <div className="card col-sm-2 me-2 text-center">
                <div className="card-header">
                  <h5 className="card-title">To order</h5>
                </div>
                <div className="card-body">

                  <h2 className="card-text">{toOrderQData.length}</h2>
                  <a href="/quotationstoorder" className="btn btn-primary">View</a>
                </div>

              </div>

            </div>

            <div className='row mt-4'>
              <hr style={{ color: 'black', height: '1px' }} />
              <h5>Shortcuts</h5>
              {userData.account_type === "user" ?
                <div className='col-sm-2 me-4' >
                  <a href='/Add' className='btn btn-lg btn-outline-success' role='button'><icons.FaPlusCircle size='3em' /> <br /> Add</a>
                </div> : null}
              {userData.account_type === "user" ?
                <div className='col-sm-2 me-4'>
                  <a href='/Issue' className='btn btn-lg btn-outline-success' role='button'><icons.FaMinusCircle size='3em' /> <br /> Issue</a>
                </div> : null}
              {userData.account_type === "admin" ?
                <div className='col-sm-2 me-4'>
                  <a href='/Adjustpluse' className='btn btn-lg btn-outline-success' role='button'><icons.FaAdjust size='3em'/> <br /> Adjust<br />+</a>
                </div> : null}
              {userData.account_type === "admin" ?
                <div className='col-sm-2 me-4'>
                  <a href='/Adjustminus' className='btn btn-lg btn-outline-success' role='button'><icons.FaAdjust size='3em'/> <br /> Adjust<br />-</a>
                </div> : null}

              {userData.account_type === "admin" || userData.account_type === "super_admin" ?
                <div className='col-sm-2 me-4'>
                  <a href='/logreport' className='btn btn-lg btn-outline-success' role='button'><icons.FaBook size='3em'/> <br /> Log<br />Report</a>
                </div> : null}

              {userData.account_type === "admin" || userData.account_type === "user" || userData.account_type === "super_admin" ?
                <div className='col-sm-2'>
                  <a href='/report' className='btn btn-lg btn-outline-success' role='button'><icons.FaFileAlt size='3em'/> <br /> Final<br />Report</a>
                </div> : null}

              <hr className='mt-4' style={{color: 'black', height: '1px'}}/>

            </div>
          </div>
        </div>
      </div>
    </div>


  )
}
