import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

const initialState = {

    qsupplier01: "", qsupplier02: "", qsupplier03: "", qsupplier04: "", remarks: "", for_verify: "",
    item_code: "", item_name: "", qbrand: "", quntity: "", unit: "", qunit_price01: "", qunit_price02: "",
    qunit_price03: "", qunit_price04: "",
    purchase_id: "", lpdate: "", lpsupplier: "", lpbrand: "", lpquntity: "", lpunit_price: "",
    use_for: "", reqest_person: "", extra_note: "", id: ""

};

export default function Editquotation() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "super_admin") {
        navigate("/");
    }

    const { quotation_ref_no } = useParams();
    const [itemNameData, setItemNameData] = useState([]);
    const [itemUnitData, setItemUnitData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [QMEdit, setQMEdit] = useState(false);
    const [QDEdit, setQDEdit] = useState(false);

    const [QMainData, setQMainData] = useState([]);
    const lodeQMainData = async () => {
        const response = await axios.get(API_URL + `/getQSuppliers/${quotation_ref_no}`);
        setQMainData(response.data[0]);

    }

    const [QDetailsData, setQDetailsData] = useState([]);
    const lodeQDetailsData = async () => {
        const response = await axios.get(API_URL + `/getQuotationDetails/${quotation_ref_no}`);
        setQDetailsData(response.data);

    }

    const handleCheckName = async (e) => {
        e.preventDefault();
        if (!item_code) {
            toast.error("Please enter the item code!");
        } else {
            const responsename = await axios.get(API_URL + `/getItemName/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemNameData(responsename.data[0]);

            const responseunit = await axios.get(API_URL + `/getItemunit/${item_code}`)
                .catch((err) => toast.error(err.response.data));
            setItemUnitData(responseunit.data[0]);

        }
    }

    useEffect(() => {
        lodeQMainData();
        lodeQDetailsData();
    }, []);

    if (QMainData.response_type === "accept" || QMainData.response_type === "reject") {
        navigate("/");
    }

    const handleMEdit = async () => {
        await axios.get(API_URL + `/getEditQSuppliers/${quotation_ref_no}`)
            .then((resp) => setState({ ...resp.data[0] }))
        setQMEdit(true);
        setQDEdit(false);
    }
    const handleDEdit = async (id) => {
        await axios.get(API_URL + `/getEditQDetails/${id}`)
            .then((resp) => setState({ ...resp.data }))
        setQDEdit(true);
        setQMEdit(false);
    }

    const [forVarifyData, setForVarifyData] = useState([]);

    const lodeForVarifyData = async () => {
        const response = await axios.get(API_URL + "/selectforvarifydata");
        setForVarifyData(response.data);

    }

    useEffect(() => {
        lodeForVarifyData();
    }, []);

    const [state, setState] = useState(initialState);

    const {
        qsupplier01, qsupplier02, qsupplier03, qsupplier04, for_verify,
        item_code, item_name, unit, quntity, qbrand, qunit_price01,
        qunit_price02, qunit_price03, qunit_price04,
        purchase_id, lpdate, lpsupplier, lpbrand, lpquntity, lpunit_price,
        remarks, use_for, reqest_person, extra_note, id } = state;

        const amount01 = qunit_price01 * quntity
        const amount02 = qunit_price02 * quntity
        const amount03 = qunit_price03 * quntity
        const amount04 = qunit_price04 * quntity

    const handleSuppliersSubmit = (e) => {
        e.preventDefault();
        if (!qsupplier01 || !qsupplier02 || !qsupplier03 || !qsupplier04 || !remarks) {
            toast.error('Please fill in all data! (If there is no data to enter in the respective field, please enter the "-" mark or "0".)');
        } else {
            setIsLoading(true);
            if (
                window.confirm("Are you sure?")
            ) {
                axios.patch(API_URL + `/editQuotationSuppliers/${quotation_ref_no}`, {
                    qsupplier01, qsupplier02, qsupplier03, qsupplier04, remarks, for_verify
                })
                    .then(() => {
                        setState({
                            qsupplier01: "", qsupplier02: "", qsupplier03: "", qsupplier04: "", remarks: "", for_verify: ""
                        });
                        lodeQMainData();
                        toast.success("Success!");
                        setIsLoading(false);
                        setQMEdit(false);
                    })
                    .catch((err) => toast.error(err.response.data));
                setIsLoading(false);
            }
        }
    }

    const handleDetailsSubmit = (e) => { 
        e.preventDefault();
        if (!item_code || !item_name) {
            toast.error('Please enter Item Code and Item Name!');
        } else {
            if (
                window.confirm("Are you sure?")
            ) {
                setIsLoading(true);
                axios.patch(API_URL + `/editQuotationDetails/${id}`, {
                    quotation_ref_no, item_code, item_name, qbrand, quntity, unit, qunit_price01, qunit_price02, qunit_price03, qunit_price04,
                    amount01, amount02, amount03, amount04, use_for, reqest_person, purchase_id, lpdate, lpsupplier, lpbrand, lpquntity, lpunit_price, extra_note

                })
                    .then(() => {
                        setState({
                            item_code: "", item_name: "", qbrand: "", quntity: "", unit: "", qunit_price01: "", qunit_price02: "",
                            qunit_price03: "", qunit_price04: "", purchase_id: "", lpdate: "", lpsupplier: "", lpbrand: "", lpquntity: "", lpunit_price: "",
                            use_for: "", reqest_person: "", extra_note: "", id: ""
                        });
                        toast.success("Success!");
                        setIsLoading(false);
                        lodeQDetailsData();
                        setQDEdit(false);
                    })
                    .catch((err) => toast.error(err.response.data));
                setIsLoading(false);
            }
        }
    }

    const currencyformatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    const handleEnter = (event) => {
        if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
        }
    }

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-5'>
            <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <h4>Edit Quotation</h4>
                        </div>

                        <div className='fright'>
                            <a href={"/dashboard"}>
                                <icons.FaHome size='2.5em' color='#274F6A' />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <div style={{ outline: "solid" }} className='mt-3' >
                            <div>
                                <div style={{ textAlign: "center" }}> <h4>Associated Speciality Rubbers (PVT) Ltd. <br /> Quotation for Purchase Order</h4></div>
                                <hr style={{ color: 'black', height: '1px', }} />
                                <div className="row">
                                    <div className="fleft">
                                        <h6 className="fleft col-sm-6">Quotation Reference No : {quotation_ref_no}</h6>
                                        <h6 className="fleft col-sm-6">Date : {moment(QMainData.date).format("YYYY/MM/DD")}</h6>
                                    </div>
                                </div>
                            </div>
                            <hr style={{ color: 'black', height: '1px', }} />
                            <h4>Supplier quotations and details</h4>
                            <div className="row px-3" >
                                <div className='table-responsive'>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr className="align-top text-center">
                                                <th rowSpan="2" scope="col">No</th>
                                                <th rowSpan="2" scope="col">Item Code</th>
                                                <th rowSpan="2" scope="col">Item Name</th>
                                                <th rowSpan="2" scope="col">Brand</th>
                                                <th rowSpan="2" scope="col">Unit</th>
                                                <th rowSpan="2" scope="col">Stock</th>
                                                <th colSpan="4" scope="col">Unit Price</th>
                                                <th rowSpan="2" scope="col">Quntity</th>
                                                <th colSpan="4" scope="col">Amount</th>
                                                <th rowSpan="2" scope="col">Extra Note</th>
                                                <th rowSpan="1" scope="col">Action </th>

                                            </tr>
                                            <tr className="text-center">
                                                <th scope="col">{QMainData.qsupplier01}</th>
                                                <th scope="col">{QMainData.qsupplier02}</th>
                                                <th scope="col">{QMainData.qsupplier03}</th>
                                                <th scope="col">{QMainData.qsupplier04}</th>
                                                <th scope="col">{QMainData.qsupplier01}</th>
                                                <th scope="col">{QMainData.qsupplier02}</th>
                                                <th scope="col">{QMainData.qsupplier03}</th>
                                                <th scope="col">{QMainData.qsupplier04}</th>
                                                <th><button type="button" className='btnNoBackground' onClick={() => handleMEdit()}><icons.FaEdit /></button></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {QDetailsData.map((qDetails, index) => (
                                                <tr key={index}>

                                                    <th scope='row'>{index + 1}</th>

                                                    <td>{qDetails.item_code}</td>
                                                    <td>{qDetails.item_name}</td>
                                                    <td>{qDetails.qbrand}</td>
                                                    <td>{qDetails.unit}</td>
                                                    <td className="text-end">{qDetails.stock}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price01)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price02)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price03)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price04)}</td>
                                                    <td className="text-end">{qDetails.quntity}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price01 * qDetails.quntity)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price02 * qDetails.quntity)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price03 * qDetails.quntity)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.qunit_price04 * qDetails.quntity)}</td>
                                                    <td>{qDetails.extra_note}</td>
                                                    <td className="text-center"><button type="button" className='btnNoBackground' onClick={() => handleDEdit(qDetails.id)}><icons.FaEdit /></button></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <dt className="col-sm-3">Last Purchase Details:</dt>
                            <div className="row px-3">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr className="text-center" >
                                            <th scope="col">No</th>
                                            <th scope="col">Purchases ID</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Supplier</th>
                                            <th scope="col">Brand</th>
                                            <th scope="col">Quntity</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Amount</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {QDetailsData.map((qDetails, index) => (
                                            qDetails.lpquntity !== 0 && qDetails.lpquntity !== null ?
                                                <tr key={index}>
                                                    <th scope='row'>{index + 1}</th>
                                                    <td>{qDetails.purchase_id}</td>
                                                    <td>{moment(qDetails.lpdate).format("YYYY/MM/DD")}</td>
                                                    <td>{qDetails.lpsupplier}</td>
                                                    <td>{qDetails.lpbrand}</td>
                                                    <td className="text-end">{qDetails.lpquntity}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.lpunit_price)}</td>
                                                    <td className="text-end">{currencyformatter.format(qDetails.lpunit_price * qDetails.lpquntity)}</td>
                                                </tr> : null
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div>
                                <dl className="row">
                                    <dt className="col-sm-2">Remarks:</dt>
                                    <dd className="col-sm-9">{QMainData.remarks}</dd>
                                </dl>
                            </div>

                        </div>
                    </div>
                </div>


                {QMEdit ? <form>
                    <div className='row'>
                        <div className='col-md-6 col-sm-12'>
                            <div>
                                <h6 className="mt-2">Suppliers</h6>
                                <input type='text' placeholder='Enter Supplier 01' className="form-control form-control-sm mt-2" name='qsupplier01' onKeyDown={handleEnter} value={qsupplier01.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 02' className="form-control form-control-sm mt-2" name='qsupplier02' onKeyDown={handleEnter} value={qsupplier02.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 03' className="form-control form-control-sm mt-2" name='qsupplier03' onKeyDown={handleEnter} value={qsupplier03.replace(",", "&") || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier 04' className="form-control form-control-sm mt-2" name='qsupplier04' onKeyDown={handleEnter} value={qsupplier04.replace(",", "&") || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div>
                                <h6 className="mt-2">Remarks:</h6>
                                <textarea type='text' placeholder='Enter Remarks' className="form-control form-control-sm" name='remarks' onKeyDown={handleEnter} value={remarks || ""} onChange={handleInputChange}></textarea>
                            </div>

                            <div>
                                <h6 className="mt-2">To send for Verify:</h6>
                                <select name="for_verify" className="form-control form-control-sm" value={for_verify} onChange={handleInputChange}>
                                    <option value="">Select who to send to for verify...</option>
                                    {
                                        forVarifyData.map((verifydata, index) => (
                                            <option value={verifydata.verifyto} key={index}>{verifydata.verifytoshow}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleSuppliersSubmit} disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form> : ""}

                {QDEdit ? <form >
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div>
                                <h6 className="mt-2">Item Code:</h6>
                                <div className='input-group'>
                                    <input type='text' name='item_code' placeholder='Enter Item Code' className="form-control form-control-sm" onKeyDown={handleEnter} value={item_code || ""} onChange={handleInputChange}></input>
                                    <button className='btn btn-sm btn-secondary' onKeyDown={handleEnter} onClick={handleCheckName}>Check Name</button>
                                </div>
                            </div>

                            <div>
                                <div className="input-group mt-2">
                                    <h6>Item Name:</h6>
                                    <label> &nbsp; &nbsp; &nbsp; {itemNameData.item_name}</label>
                                </div>
                            </div>

                            <div>
                                <h6 className="mt-2">Brand:</h6>
                                <input type='text' placeholder='Enter Brand' className="form-control form-control-sm" name='qbrand' onKeyDown={handleEnter} value={qbrand || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Qunatity:</h6>
                                <div className='input-group'>
                                    <input type='number' placeholder='Enter Item Quantity' className="form-control form-control-sm" name='quntity' onKeyDown={handleEnter} value={quntity || ""} onChange={handleInputChange}></input>
                                    <label > &nbsp; {itemUnitData.unit}</label>
                                </div>
                            </div>

                            <div>
                                <h6 className="mt-2">Unit Price:</h6>
                                <input type='number' placeholder='Enter Supplier 01 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price01' onKeyDown={handleEnter} value={qunit_price01 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 02 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price02' onKeyDown={handleEnter} value={qunit_price02 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 03 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price03' onKeyDown={handleEnter} value={qunit_price03 || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Supplier 04 Unit Price' className="form-control form-control-sm mt-1" name='qunit_price04' onKeyDown={handleEnter} value={qunit_price04 || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div>
                                <h6 className="mt-2">Use For:</h6>
                                <input type='text' placeholder='Enter Use For' className="form-control form-control-sm" name='use_for' onKeyDown={handleEnter} value={use_for || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Reqest Person:</h6>
                                <input type='text' placeholder='Enter Reqest Person' className="form-control form-control-sm" name='reqest_person' onKeyDown={handleEnter} value={reqest_person || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Last Purchase</h6>
                                <input type="date" className="form-control form-control-sm" name='lpdate' onKeyDown={handleEnter} value={lpdate || ""} onChange={handleInputChange}></input>
                                <input type="text" placeholder='Enter Purchase ID' className="form-control form-control-sm mt-1" name='purchase_id' value={purchase_id || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Supplier' className="form-control form-control-sm mt-1" name='lpsupplier' onKeyDown={handleEnter} value={lpsupplier || ""} onChange={handleInputChange}></input>
                                <input type='text' placeholder='Enter Brand' className="form-control form-control-sm mt-1" name='lpbrand' onKeyDown={handleEnter} value={lpbrand || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Item Quntity' className="form-control form-control-sm mt-1" name='lpquntity' onKeyDown={handleEnter} value={lpquntity || ""} onChange={handleInputChange}></input>
                                <input type='number' placeholder='Enter Unit Price' className="form-control form-control-sm mt-1" name='lpunit_price' onKeyDown={handleEnter} value={lpunit_price || ""} onChange={handleInputChange}></input>
                            </div>

                            <div>
                                <h6 className="mt-2">Extra Note:</h6>
                                <input type='text' placeholder='Enter Extra Note' className="form-control form-control-sm" name='extra_note' onKeyDown={handleEnter} value={extra_note || ""} onChange={handleInputChange}></input>
                            </div>
                        </div>
                        <div className="text-end">
                            <button type='submit' className='btn btn-sm btn-success mt-2' onClick={handleDetailsSubmit} disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}</button>
                        </div>
                    </div>
                </form> : ""}
            </div>
            <ToastContainer />
        </div>
    )
}
