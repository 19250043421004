import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';
import API_URL from '../config/config';
import * as icons from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import ReactExport from 'react-data-export';

const initialState={
    supplier_id:"",
    name:"",
    filename:""
    
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Suppliers() {

        const navigate = useNavigate();
        const [cookies] = useCookies([]);
        
        useEffect(() => {
            const verifyUser = async () => {
              if (!cookies.jwt) {
                navigate("/");
              } 
            };
            verifyUser();
          },
          );

    const [userData, setUserData]=useState([]);
  
    const lodeUserData=async()=>{
        await axios.post(API_URL + "/profile",{},
          {
            withCredentials: true,
          })
          .then((resp)=>setUserData({...resp.data[0]}))
        
    }

    function setNoshowUser(){
        if(userData.account_type==="admin"){
          return true;
        }else{
          return false;
        }
      }
    const noshowUser=setNoshowUser();
    
      function setNohowAdmin(){
        if(userData.account_type==="user"){
          return true;
        }else{
          return false;
        }
      }
    const noshowAdmin=setNohowAdmin();

    const [data, setData]=useState([]);
    const lodeData=async()=>{
        const response=await axios.get(API_URL + "/allSuppliers");
        setData(response.data);
    }

    const[state, setState]=useState(initialState);
    const{supplier_id, name, filename}=state;
    

    const handleTransNo=async(e)=>{
        e.preventDefault();
        const response=await axios.get(API_URL + `/searchSuppliersID/${supplier_id}`)
        setData(response.data);
    }


    const handleSuppliername=async(e)=>{
        e.preventDefault();
        const response=await axios.get(API_URL + `/searchSuppliername/${name}`)
        setData(response.data);
    }

    const handleReset=(e)=>{
        
            lodeData()
            .then(()=>{
                setState({
                    supplier_id:"",
                    name:"",
                    filename:""
                });
            });
      };

      const DataSet = [
        
        {
            columns: [
                {title: "Supplier ID", style: {font: {sz: "12", bold: true}}, width: {wpx: 60}}, 
                {title: "Name", style: {font: {sz: "12", bold: true}}, width: {wpx: 250}}, 
                {title: "Address", style: {font: {sz: "12", bold: true}}, width: {wpx: 250}}, 
                {title: "Contact No", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}},
                {title: "Email", style: {font: {sz: "12", bold: true}}, width: {wpx: 200}},
                {title: "SVAT Registration", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Account No", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}}, 
                {title: "Account Name", style: {font: {sz: "12", bold: true}}, width: {wpx: 120}},
                {title: "Account Bank", style: {font: {sz: "12", bold: true}}, width: {wpx: 120}},
                {title: "Account Barnch", style: {font: {sz: "12", bold: true}}, width: {wpx: 100}},
                {title: "Account Branch Code", style: {font: {sz: "12", bold: true}}, width: {wpx: 80}},

                
            ],
           
            data: data.map((suppliers) => [
                                           
                {value: suppliers.supplier_id, style:{font: {sz: "12"}}},
                {value: suppliers.name, style: {font: {sz: "12"}}},
                {value: suppliers.address, style: {font: {sz: "12"}}},
                {value: suppliers.contact_no, style: {font: {sz: "12"}}},
                {value: suppliers.email, style: {font: {sz: "12"}}},
                {value: suppliers.svat, style: {font: {sz: "12"}}},
                {value: suppliers.account_no, style:{font: {sz: "12"}}},
                {value: suppliers.account_name, style:{font: {sz: "12"}}},
                {value: suppliers.account_bank, style:{font: {sz: "12"}}},
                {value: suppliers.account_branch, style:{font: {sz: "12"}}},
                {value: suppliers.account_branch_code, style:{font: {sz: "12"}}},
               
                ])
        }
    ]
    

    const handleInputChange=(e)=>{
        const{name,value} = e.target;
        setState({...state,[name]:value})
      };


    useEffect(()=>{
        lodeUserData();
        lodeData();
    },[]);



    return (
        <div className='background'>
        <div className='container-fluid contentbox p-4'>
               
                <div className='row'>
                        <div className=' col-12'>
                            <div className='fleft mx-3'>  
                                <a href="/purchasingmanagement">
                                    <icons.FaArrowCircleLeft size='2.5em' color='#274F6A'/>
                                </a>
                            </div>
                        
                            <div className='fleft'>
                                <h2>Suppliers</h2>
                            </div>
                        </div>
                    
                    </div>

                    <div className=' col-12'>

                        <div className='row'>
                            <div>  
                            
                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Supplier ID...' name='supplier_id' value={supplier_id} onChange={handleInputChange}></input>
                                        
                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleTransNo}><icons.FaSearch/></button>
                                </div>
                                </div>

                                <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="search" className="form-control" placeholder='Supplier Name...' name='name' value={name} onChange={handleInputChange}></input>
                                        
                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleSuppliername}><icons.FaSearch/></button>
                                </div>
                                </div>
                
                                <div className='fleft mt-2 mx-3 mt-2'>
                                    <button type='button' className='btn btn-success ' onClick={handleReset}>Reset</button>
                                </div>

                                <div className='input-group'>
                                    <div className='mt-2 mx-3'>
                                        <div className="form-outline fleft ">
                                            <input type='text' className="form-control" placeholder='Enter File Name' name='filename' value={filename} onChange={handleInputChange}></input>
                                        </div>
                                        <ExcelFile 
                                            filename={filename || "Suppliers"}
                                            element={<button type="button" className="btn btn-success">Export Data</button>}>
                                            <ExcelSheet dataSet={DataSet} name="Items List"/>
                                        </ExcelFile>
                                    </div>
                                
                                {noshowAdmin ?<div className='fleft mx-3 mt-2'><a href='/addsuppliers' className='btn btn-outline-success' role='button'><icons.FaPlusCircle/> Add Suppliers </a></div>:null}
                                </div>
                            </div> 
                        </div>

                        <div className='mt-3 mx-3'>
                           
                            <div className='table-responsive me-3'>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Supplier<br/>ID</th>
                                        <th scope="col">Supplier<br/>Name</th>
                                        <th scope="col">Address:</th>
                                        <th scope="col">Contact<br/>No</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">SVAT<br/>Registration</th>
                                        <th scope="col">Account<br/>No</th>
                                        <th scope="col">Account<br/>Name</th>
                                        <th scope="col">Account<br/>Bank</th>
                                        <th scope="col">Account<br/>Branch</th>
                                        <th scope="col">Account<br/>Branch<br/>Code</th>
                                        {noshowUser ? <th scope="col">Action</th>:null}
                                        
                                    </tr>
                                </thead>
                            
                                <tbody>
                                {data.map((suppliers,index)=>(
                                        <tr key={index}>
                                                <td>{suppliers.supplier_id}</td>                                        
                                                <td>{suppliers.name}</td>
                                                <td>{suppliers.address}</td>
                                                <td>{suppliers.contact_no}</td>
                                                <td>{suppliers.email}</td>
                                                <td>{suppliers.svat}</td>
                                                <td>{suppliers.account_no}</td>
                                                <td>{suppliers.account_name}</td>
                                                <td>{suppliers.account_bank}</td>
                                                <td>{suppliers.account_branch}</td>
                                                <td>{suppliers.account_branch_code}</td>
                                                
                                                {noshowUser ?
                                                <td className='d-flex'>
                                                    <Link to={`/editsuppliers/${suppliers.supplier_id}`}>
                                                    <icons.FaEdit/>
                                                    </Link>
                                                    
                                                </td>
                                                :null}
                                        
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
  }
