import React, { useState, useEffect } from 'react'
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";


export default function Viewsuppliers(){

    const navigate = useNavigate();
    const [cookies] = useCookies([]);
            
      useEffect(() => {
        const verifyUser = async () => {
          if (!cookies.jwt) {
            navigate("/");
          } 
        };
        verifyUser();
      });

    const supplier_id = window.location.href.split('/')[4];

    const [data, setData]=useState([]);


        useEffect(()=>{
            const lodeData=async()=>{
                const response=await axios.get(API_URL + `/oneSupplier/${supplier_id}`);
                setData(response.data[0]);
            }
            lodeData();
        },[supplier_id]);

    return (
        <div className='background'>
                <div className='container-fluid contentbox p-5'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='fleft'>  
                                    <a href="/purchases">
                                        <icons.FaArrowCircleLeft size='2.5em' color='#274F6A'/>
                                    </a>
                                </div>
                                
                                <div className='fleft ms-2'>
                                    <h2>View Supplier</h2>
                                </div>
                            </div>
                        </div>

                        
                            <div className='mt-4'>
                                <dl className="row">
                                    <dt className="col-sm-3">Supplier ID:</dt>
                                    <dd className="col-sm-9">{data.supplier_id}</dd>
                                </dl>


                                <dl className="row">
                                    <dt className="col-sm-3">Supplier Name:</dt>
                                    <dd className="col-sm-9">{data.name}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Address:</dt>
                                    <dd className="col-sm-9">{data.address}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Contact No:</dt>
                                    <dd className="col-sm-9">{data.contact_no}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Email:</dt>
                                    <dd className="col-sm-9">{data.email}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">SVAT Registration:</dt>
                                    <dd className="col-sm-9">{data.svat}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Account No:</dt>
                                    <dd className="col-sm-9">{data.account_no}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Account Name:</dt>
                                    <dd className="col-sm-9">{data.account_name}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Account Bank:</dt>
                                    <dd className="col-sm-9">{data.account_bank}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Account Branch:</dt>
                                    <dd className="col-sm-9">{data.account_branch}</dd>
                                </dl>

                                <dl className="row">
                                    <dt className="col-sm-3">Account Branch Code:</dt>
                                    <dd className="col-sm-9">{data.account_branch_code}</dd>
                                </dl>
                            </div>
                    </div>
        </div>
    )
}
