import React, { useState} from "react";
import * as icons from 'react-icons/fa';
import './Login.css';
import API_URL from '../config/config';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const initialState={
  username:"",
  password:""
}
export default function Login(){

  const navigate = useNavigate();

  const[state, setState]=useState(initialState);
  
      const{username, password}=state;
      
      const handleSubmit=(e)=>{
          e.preventDefault();
          if (!username || !password ) {
              toast.error("Please enter Username and Password");
          }else{
          
              axios.post(API_URL + "/login",{
                username,
                password
  
              },{ withCredentials: true })

              .then(()=>{
                setState({
                  username:"",
                  password:""
                });
                navigate("/dashboard")
            })  
          
            .catch((err)=>toast.error(err.response.data));
            }}
      
      const handleInputChange=(e)=>{
          const{name,value} = e.target;
          setState({...state,[name]:value})
        };

        const handleEnter = (event) => {
          if (event.key.toLowerCase() === "enter") {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
          }}

    return (
      <div >
            <div className='backgroundlogin d-flex justify-content-center align-items-center'>
              <div className='loginbox d-flex justify-content-center align-items-center'>
                <div>
                  <icons.FaUserCircle size='7em' color='CCCCCC' className='mx-auto d-block'/>
                  <h2 className='text-center'>
                    LOGIN 
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <input type='text' placeholder='Username' className="form-control mt-3" name="username" 
                    value={username} onKeyDown={handleEnter} onChange={handleInputChange}></input>

                    <input type='password' placeholder='Password' className="form-control mt-3" name="password"
                    value={password} onChange={handleInputChange}></input>

                    <div className='d-grid'> <button className='btn btn-success mt-3' type="submit">Login</button> </div>
                  </form>

                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
    )
 }
