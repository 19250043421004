import React, { useState, useEffect } from 'react';
import './Pages.css';
import API_URL from '../config/config';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import * as icons from 'react-icons/fa';
import ReactExport from 'react-data-export';

const initialState = {
    startdate: "",
    enddate: "",
    filename:""

}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function Report() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [state, setState] = useState(initialState);
    const { startdate, enddate, filename } = state;

    const [data, setData] = useState([]);

    const filterData = async (e) => {
        axios.get(API_URL + `/filterreportat/${startdate}/${enddate}`)
    }

    const lodeData = async (e) => {
        const response = await axios.get(API_URL + "/getreportat");
        setData(response.data);
    }

    const handleGetReport = async (e) => {
        e.preventDefault();
        filterData()
            .then(() => {
                lodeData();
            });
    };

    const DataSet = [
        {
            columns: [
                { title: "Item Code", style: { font: { sz: "12", bold: true } }, width: { wpx: 80 } },
                { title: "Item Name", style: { font: { sz: "12", bold: true } }, width: { wpx: 300 } },
                { title: "Add", style: { font: { sz: "12", bold: true } }, width: { wpx: 100 } },
                { title: "Issue", style: { font: { sz: "12", bold: true } }, width: { wpx: 100 } },
                { title: "Adjust +", style: { font: { sz: "12", bold: true } }, width: { wpx: 100 } },
                { title: "Adjust -", style: { font: { sz: "12", bold: true } }, width: { wpx: 100 } },
                { title: "Net Value", style: { font: { sz: "12", bold: true } }, width: { wpx: 100 } }
            ],

            data: data.map((culations) => [
                { value: culations.item_code, style: { font: { sz: "12" } } },
                { value: culations.item_name, style: { font: { sz: "12" } } },
                { value: culations.addcalculation, style: { font: { sz: "12" } } },
                { value: culations.issuecalculation, style: { font: { sz: "12" } } },
                { value: culations.adjustpcalculation, style: { font: { sz: "12" } } },
                { value: culations.adjustmcalculation, style: { font: { sz: "12" } } },
                { value: (culations.addcalculation+culations.adjustpcalculation)-(culations.issuecalculation+culations.adjustmcalculation), style: { font: { sz: "12" } } }
            ])
        }
    ]


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };

    return (
        <div className='background'>
            <div className='container-fluid contentbox p-4'>

                <div className='row'>
                    <div className=' col-12'>
                        <div className='fleft mx-3'>
                            <a href="/dashboard">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft'>
                            <h2>Report</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className=' col-12'>

                        <div className='row'>
                            <div className='fleft mx-3 mt-2'>
                                <div className='input-group'>
                                    <div className="form-outline">
                                        <input type="date" className="form-control" name='startdate' value={startdate} onChange={handleInputChange}></input>
                                    </div>
                                    <div className="form-outline">
                                        <input type="date" className="form-control" name='enddate' value={enddate} onChange={handleInputChange}></input>
                                    </div>
                                    <button type="button" className="btn btn-success" onClick={handleGetReport}><icons.FaFilter /></button>
                                </div>
                            </div>

                            <div className='input-group'>
                            <div className='mt-2 mx-3'>
                                <div className="form-outline fleft ">
                                    <input type='text' className="form-control" placeholder='Enter File Name' name='filename' value={filename} onChange={handleInputChange}></input>
                                </div>
                                <ExcelFile
                                    filename={filename || "Report"}
                                    element={<button type="button" className="btn btn-success">Export Data</button>}>
                                    <ExcelSheet dataSet={DataSet} name="Items List" />
                                </ExcelFile>
                            </div>
                        </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='table-responsive me-3'>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">No</th>
                                            <th scope="col">Item<br />Code</th>
                                            <th scope="col">Item<br />Name</th>
                                            <th scope="col">Add</th>
                                            <th scope="col">Issue</th>
                                            <th scope="col">Adjust +</th>
                                            <th scope="col">Adjust -</th>
                                            <th scope="col">Net Value</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data.map((culations, index) => (
                                            <tr key={index}>
                                                <th scope='row'>{index + 1}</th>
                                                <td>{culations.item_code}</td>
                                                <td>{culations.item_name}</td>
                                                <td style={{ textAlign: "right" }}>{culations.addcalculation}</td>
                                                <td style={{ textAlign: "right" }}>{culations.issuecalculation}</td>
                                                <td style={{ textAlign: "right" }}>{culations.adjustpcalculation}</td>
                                                <td style={{ textAlign: "right" }}>{culations.adjustmcalculation}</td>
                                                <td style={{ textAlign: "right" }}>{(culations.addcalculation+culations.adjustpcalculation)-(culations.issuecalculation+culations.adjustmcalculation)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}