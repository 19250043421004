import React, { useState, useEffect } from 'react';
import * as icons from 'react-icons/fa';
import './Pages.css';
import API_URL from '../config/config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const initialState = {
    name: "",
    position: "",
    email: "",
    contact_no: "",
    account_type: ""

};

export default function Edituser() {

    const navigate = useNavigate();
    const [cookies] = useCookies([]);

    useEffect(() => {
        const verifyUser = async () => {
            if (!cookies.jwt) {
                navigate("/");
            }
        };
        verifyUser();
    });

    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const lodeUserData = async () => {
            await axios.post(API_URL + "/profile", {},
                {
                    withCredentials: true,
                })
                .then((resp) => setUserData({ ...resp.data[0] }))
        }
        lodeUserData();
    }, []);

    if (userData.account_type === "user" || userData.account_type === "super_admin") {
        navigate("/");
    }

    const [state, setState] = useState(initialState);

    const user_id = window.location.href.split('/')[4];
    const { name, position, email, contact_no, account_type } = state;
    const userid = userData.user_id;
    const user_fname = userData.name;



    useEffect(() => {
        axios.get(API_URL + `/oneUser/${user_id}`)
            .then((resp) => setState({ ...resp.data[0] }))
    }, [user_id])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name || !position || !email || !contact_no || !account_type) {
            toast.error("Please fill in all data!");
        } else {
            if (
                window.confirm("Are you sure?")
            ) {
                axios.patch(API_URL + `/updateUser/${user_id}`, {
                    name,
                    position,
                    email,
                    contact_no,
                    account_type,
                    userid,
                    user_fname

                })
                    .then(() => {

                        toast.success("Success!");
                    })
                    .catch((err) => toast.error(err.response.data));

            }
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
    };


    return (

        <div className='background'>
            <div className='container-fluid contentbox p-5'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='fleft'>
                            <a href="/useraccount">
                                <icons.FaArrowCircleLeft size='2.5em' color='#274F6A' />
                            </a>
                        </div>

                        <div className='fleft ms-2'>
                            <h2>Edit User</h2>

                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-11 col-md-6 col-sm-9 mt-2'>

                        <form onSubmit={handleSubmit}>
                            <div>
                                <h5 className="mt-2">Name:</h5>
                                <input type='text' name='name' placeholder='Enter Name' className="form-control" value={name || ""} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Position:</h5>
                                <input type='text' name='position' placeholder='Enter Position' className="form-control" value={position || ""} onChange={handleInputChange} >
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Email:</h5>
                                <input type='email' name='email' placeholder='Enter Email' className="form-control" value={email || ""} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Contact No:</h5>
                                <input type='text' name='contact_no' placeholder='Enter Contact number' className="form-control" value={contact_no || ""} onChange={handleInputChange}>
                                </input>
                            </div>

                            <div>
                                <h5 className="mt-2">Account Type:</h5>
                                <select name='account_type' className="form-control" value={account_type || ""} onChange={handleInputChange}>
                                    <option></option>
                                    <option value="user">User</option>
                                    <option value="admin">Admin</option>
                                    <option value="super_admin">Super Admin</option>
                                </select>
                            </div>


                            <button type='submit' className='btn btn-success mt-2 ' >Update</button>
                        </form>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>
    )
}
